<template>
  <div
    class="__trend_bg_seperation flex"
    @contextmenu="this.setContextIdWidget"
  >
    <!-- without image -->
    <div
      class="block tool_group"
      :style="{
        top: this.mouse_y + 'px',
        left: this.mouse_x + 'px',
      }"
    >
      <p v-if="isActcBarHover" class="actc_tool text-white">
        {{ actcBarMessage }}
      </p>
    </div>

    <!-- without image end -->

    <div
      :id="'main' + compId"
      :style="{
        height: wh.height + 'px',
        width: wh.width + 'px',
      }"
      :class="darkDark !== 'white' ? 'noUi-target_dup' : 'light_trend-mode'"
      class="arrangementClass"
    >
      <div class="flex justify-between">
        <div
          v-if="logType == 'time'"
          class="ml-0 mr-1 flex text-center text-sm mt-2"
        >
          <span style="font-size: 5px; height: 1.9rem" class="flex">
            <div
              v-if="logType == 'time'"
              class="flex flex-col"
              style="height: fit-content"
            >
              <Datepicker
                class="disTimeLogDatePicker"
                v-model="scaleMinDate"
                :minDate="
                  new Date(scaleMinFixed).toLocaleString('en-US', {
                    timeZone: this.time_zone,
                  })
                "
                :maxDate="
                  new Date(scaleMaxFixed).toLocaleString('en-US', {
                    timeZone: this.time_zone,
                  })
                "
                :dark="darkDark != 'white'"
                textInput
                placeholder="MM/DD/YYYY"
                :enableTimePicker="false"
                @update:modelValue="onFromDateChange"
              />
            </div>
            <div
              class="flex ml-1"
              :class="this.isDis == false ? 'non_dis_sec' : ''"
              :style="{ gap: '6px' }"
            >
              <select
                v-model.number="currentTimeRange"
                @change="selectTimeRange($event.target.value, true)"
                class="depth_pick newStWDeptInput"
                :style="{
                  borderRadius: '0px',
                  padding: '0px',
                  width: '100px !important',
                }"
              >
                <option value="1">1 HR</option>
                <option value="2">2 HRS</option>
                <option value="4">4 HRS</option>
                <template v-if="!isReplay">
                  <option
                    :selected="currentTimeRange == time"
                    v-for="(time, i) in timeRanges"
                    :key="i"
                    :value="time"
                  >
                    {{ timeRangesNames[i] }}
                  </option>
                </template>
              </select>
              <div v-if="isReplay">
                <div
                  :style="{
                    fontSize: 10 + 'px',
                    marginLeft: '-1px',
                  }"
                >
                  <button
                    class="timeBtn normalBg"
                    @click="setAutoPlay(!isAuto)"
                  >
                    {{ isAuto ? "Stop" : "Auto" }}
                  </button>
                </div>
              </div>
              <!-- <div
                :style="{
                  display: 'flex',
                  fontSize: '1.2rem',
                  marginTop: '0rem',
                }"
              >
                <button class="ml-2" @click="clickZoomHandler(true)">
                  <i class="fas fa-search-plus"></i>
                </button>
                <button class="ml-3" @click="clickZoomHandler(false)">
                  <i class="fas fa-search-minus"></i>
                </button>
              </div> -->
              <!-- <div v-for="(time, i) in timeRanges" :key="i">
                <div :style="{
                  fontSize: 10 + 'px',
                  marginTop: '-4px',
                  marginLeft: '-1px',
                }">
                  <button class="timeBtn" :class="currentTimeRange == time ? 'activeBg' : 'normalBg'"
                    @click="selectTimeRange(time, true)">
                    <div class="flex">
                      <span>{{ time }}</span>
                      <span class="ml-1">{{ time == 1 ? " HR" : " HRS" }}</span>
                    </div>
                  </button>
                </div>
              </div> -->
            </div>
          </span>
        </div>

        <div
          v-if="logType == 'depth'"
          class="ml-0 mr-1 flex text-center text-sm mt-2"
        >
          <!-- <span class="text_xs feet_">Feet :</span> -->
          <span style="font-size: 5px; height: 1.9rem">
            <div
              class="flex ml-1"
              :class="this.isDis == false ? 'non_dis_sec' : ''"
              :style="{ gap: '6px', width: leftbarWidth + sliderWidth + 'px' }"
            >
              <select
                @change="selectFeet($event.target.value, true)"
                class="depth_pick newStWDeptInput"
                :style="{ borderRadius: '10px' }"
              >
                <option
                  v-if="feetRange.indexOf(Number(currentFeetRange)) == -1"
                  :value="currentFeetRange"
                  :selected="feetRange.indexOf(currentFeetRange) == -1"
                >
                  {{ currentFeetRange }} FEETs
                </option>
                <option
                  :selected="currentFeetRange == feet"
                  v-for="(feet, i) in feetRange"
                  :key="i"
                  :value="feet"
                >
                  {{ feet }} FEETs
                </option>
              </select>
              <!-- <div
                :style="{
                  display: 'flex',
                  fontSize: '1.2rem',
                  marginTop: '0rem',
                }"
              >
                <button class="ml-2" @click="clickZoomHandler(true)">
                  <i class="fas fa-search-plus"></i>
                </button>
                <button class="ml-3" @click="clickZoomHandler(false)">
                  <i class="fas fa-search-minus"></i>
                </button>
              </div> -->
              <!-- <div v-for="(feet, i) in feetRange" :key="i">
                <div :style="{
                  fontSize: 10 + 'px',
                  marginTop: '-4px',
                  marginLeft: '-1px',
                }">
                  <button class="timeBtn" style="padding: 2px 3px"
                    :class="currentFeetRange == feet ? 'activeBg' : 'normalBg'" @click="selectFeet(feet, true)">
                    <div class="flex">
                      <span>{{ feet }}</span>
                      <span>FT</span>
                    </div>
                  </button>
                </div>
              </div> -->
            </div>
          </span>
        </div>

        <div v-if="logType == 'time' && isReplay">
          <div class="flex justify-around mt-1">
            <div class="flex items-center justify-between __toggle__boxes">
              <div class="flex">
                <div class="mt-1 settingPopColor mb-1" :class="'white-white'">
                  Stream speed &nbsp;
                </div>
                <div class="ml-0.5 tool_section">
              <select
                v-model.number="replayDataInterval"
                @change="replayDataIntervalChangeHandler"
                class="depth_pick newStWDeptInput"
                :style="{ borderRadius: '0px',maxWidth:'150px', padding: '0px', width: '100px !important' }"
              >
              <template v-if="logInterval && logInterval!='5_sec'">
                <option value="1" >1 Sec</option>
                <option value="5">5 Sec</option>
                <option value="10">10 Sec</option>
                <option value="60">1 Min</option>
                <option value="900">15 Min</option>
                <option value="1800">30 Min</option>
              </template>
              <template v-else>
                <option value="1">5 Sec</option>
                <option value="2">10 Sec</option>
                <option value="12">1 Min</option>
                <option value="180">15 Min</option>
                <option value="360">30 Min</option>
              </template>



              </select>
              
              
        
                </div>
              </div>
            </div>
            <div class="flex items-center justify-between __toggle__boxes">
              <div class="flex">
                <div class="mt-1 settingPopColor mb-1" :class="'white-white'">
                  Alert Annotations
                </div>
                <div class="ml-0.5 tool_section">
                  <input
                    type="checkbox"
                    :id="'alertswitch' + this.id"
                    class="tool_toggle_input"
                    v-bind:value="isShowAlerts"
                    @click="changeAlert()"
                  />
                  <label
                    class="tool_toggle_label ml-1 mt-1"
                    :class="'_alert'"
                    :for="'alertswitch' + this.id"
                    >Alerts :</label
                  >
                </div>
              </div>
            </div>
            <div class="flex items-center justify-between __toggle__boxes">
              <div class="flex">
                <div
                  class="mt-1 settingPopColor mb-1"
                  :class="false ? 'text-gray-600' : 'white-white'"
                >
                  Tooltip
                </div>
                <div class="ml-0.5 tool_section">
                  <input
                    type="checkbox"
                    :id="'switch' + this.id"
                    class="tool_toggle_input"
                    v-bind:value="isShowTooltip"
                    @click="changeTooltip()"
                  />
                  <label
                    class="tool_toggle_label ml-1 mt-1"
                    :class="false ? 'muted_bg' : '_tooltip'"
                    :for="'switch' + this.id"
                    >Tooltip :</label
                  >
                </div>
              </div>
            </div>
            <div class="flex items-center justify-between __toggle__boxes">
              <div class="flex">
                <div class="mt-1 settingPopColor mb-1">Zoom</div>
                <div class="ml-0.5 tool_section">
                  <input
                    type="checkbox"
                    :id="'zoomswitch' + this.id"
                    :checked="this.isZoom"
                    class="tool_toggle_input"
                    v-bind:value="isZoom"
                    @click="changeZoom()"
                  />
                  <label
                    class="tool_toggle_label _zoom ml-1 mt-1"
                    :for="'zoomswitch' + this.id"
                    >Zoom :</label
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="logType"
        class="flex justify-start bg-white"
        :style="{
          width: templateWidth + 'px',
          height: bheight + 'px',
          background: 'var(--widgetBg)',
          transition: 'var(--transition)',
        }"
      >
        <div
          class="text-white"
          :style="{
            zIndex: 0,
            height: bheight + 'px',
            width: leftbarWidth + sliderWidth + 'px',
          }"
        >
          <div
            id="tempdiv"
            class="relative flex track_config_border bg-opacity-99 __bg_label_boxes"
            :style="{
              width: leftbarWidth + sliderWidth + 'px',
              marginRight: 0 + 'px',
              height: 45 + 'px',
              borderBottom: 'solid 0.5px darkgray',
            }"
          >
            <div class="t_d_col_1">
              <button
                class="colorbox"
                :style="{
                  backgroundColor: 'darkgray',
                }"
              />
            </div>
            <div
              :class="'curve_pop_opened'"
              :style="{ right: singleTrackWidth + 2 + 'px' }"
            ></div>
            <div
              class="name_numaric_con flex flex-col justify-center"
              :class="
                this.darkDark != 'white'
                  ? ' tooltip__tip_dark'
                  : ' tooltip__tip_light'
              "
            >
              <div
                v-if="logType == 'time'"
                class="flex flex-col"
                style="height: fit-content"
                title="Well Start/End Date"
              >
                <div
                  class="mt-0 flex flex-col text_xs"
                  style="
                    font-size: 0.6rem;
                    color: var(--textColor);
                    font-weight: var(--fontWeight);
                    margin: auto;
                  "
                >
                  <span>WELL START/END TIME</span>
                </div>
                <div style="gap: 10px; margin: auto" class="mt-1 flex flex-col">
                  <div
                    class="mt-0 flex flex-col text_xs"
                    style="
                      font-size: 0.58rem;
                      color: var(--textColor);
                      font-weight: var(--fontWeight);
                    "
                  >
                    <span>
                      {{
                        this.moment(scaleMinFixed).format("MM-DD-YYYY HH:mm:ss")
                      }}
                    </span>
                  </div>
                </div>
                <div
                  class="mt-0 flex flex-col text_xs"
                  style="
                    font-size: 0.58rem;
                    color: var(--textColor);
                    font-weight: var(--fontWeight);
                    margin: auto;
                  "
                >
                  <span>{{
                    this.moment(scaleMaxFixed).format("MM-DD-YYYY HH:mm:ss")
                  }}</span>
                </div>
              </div>
              <div v-if="logType == 'depth'" class="flex mt-1">
                <div class="block">
                  <div :style="{ rowGap: '2px' }" class="flex flex-col">
                    <div
                      class="mt-0 flex flex-col text_xs"
                      style="
                        font-size: 0.6rem;
                        color: var(--textColor);
                        font-weight: var(--fontWeight);
                        margin: auto;
                      "
                    >
                      <span>WELL DEPTHs</span>
                    </div>
                    <div class="ml-0.5 tool_section newStWDepth">
                      <input
                        :style="{ height: '10px !important' }"
                        disabled
                        type="number"
                        :value="
                          !isNaN(this.scaleMinFixed)
                            ? this.scaleMinFixed.toFixed(0)
                            : 0
                        "
                        class="depth_pick newStWDeptInput"
                      />
                    </div>
                    <div class="ml-0.5 tool_section w-full newStWDepth">
                      <input
                        :style="{ height: '10px !important' }"
                        disabled
                        type="number"
                        :value="
                          !isNaN(this.scaleMaxFixed)
                            ? this.scaleMaxFixed.toFixed(0)
                            : 0
                        "
                        class="depth_pick newStWDeptInput"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            id="tempdiv"
            class="relative flex track_config_border bg-opacity-99 __bg_label_boxes"
            :class="getAlertClassOnCondition(curve)"
            :style="{
              width: leftbarWidth + sliderWidth + 'px',
              marginRight: 0 + 'px',
              height: 30 + 'px',

              borderBottom: 'solid 0.5px darkgray',
            }"
          >
            <div class="t_d_col_1">
              <button
                class="colorbox"
                :style="{
                  backgroundColor: 'darkgray',
                }"
              />
            </div>

            <div
              class="name_numaric_con flex flex-col justify-center"
              :class="
                this.darkDark != 'white'
                  ? ' tooltip__tip_dark'
                  : ' tooltip__tip_light'
              "
              :style="{ display: 'flex !important' }"
            >
              <!-- <div
                v-if="logType == 'time'"
                class="flex flex-col"
                style="height: fit-content"
              >
                <Datepicker
                  v-model="scaleMinDate"
                  :minDate="
                    new Date(scaleMinFixed).toLocaleString('en-US', {
                      timeZone: this.time_zone,
                    })
                  "
                  :maxDate="
                    new Date(scaleMaxFixed).toLocaleString('en-US', {
                      timeZone: this.time_zone,
                    })
                  "
                  :dark="darkDark != 'white'"
                  textInput
                  placeholder="MM/DD/YYYY"
                  :enableTimePicker="false"
                  :timezone="this.time_zone ? this.time_zone : null"
                  @update:modelValue="onFromDateChange"
                />
              </div> -->
              <div v-if="logType == 'depth'" class="flex mt-1">
                <div class="block"></div>
              </div>
            </div>
          </div>
          <div
            id="tempdiv"
            class="relative flex track_config_border bg-opacity-99 __bg_label_boxes"
            :class="getAlertClassOnCondition(curve)"
            :style="{
              width: leftbarWidth + sliderWidth + 'px',
              marginRight: 0 + 'px',
              height: 45 + 'px',
            }"
          >
            <div class="t_d_col_1">
              <button
                class="colorbox"
                :style="{
                  backgroundColor: 'darkgray',
                }"
              />
            </div>

            <div
              class="name_numaric_con flex flex-col justify-center"
              :class="
                this.darkDark != 'white'
                  ? ' tooltip__tip_dark'
                  : ' tooltip__tip_light'
              "
            >
              <div
                v-if="logType == 'time'"
                class="flex flex-col"
                style="height: fit-content"
                title="Scale Start/End Date"
              >
                <div
                  class="mt-0 flex flex-col text_xs"
                  style="
                    font-size: 0.6rem;
                    color: var(--textColor);
                    font-weight: var(--fontWeight);
                    margin: auto;
                  "
                >
                  <span>SCALE START/END TIME</span>
                </div>
                <div style="gap: 10px; margin: auto" class="mt-1 flex flex-col">
                  <div
                    class="mt-0 flex flex-col text_xs"
                    style="
                      font-size: 0.58rem;
                      color: var(--textColor);
                      font-weight: var(--fontWeight);
                    "
                  >
                    <span>
                      {{ this.moment(scaleMin).format("MM-DD-YYYY HH:mm:ss") }}
                    </span>
                  </div>
                </div>
                <div
                  class="mt-0 flex flex-col text_xs"
                  style="
                    font-size: 0.58rem;
                    color: var(--textColor);
                    font-weight: var(--fontWeight);
                    margin: auto;
                  "
                >
                  <span>{{
                    this.moment(scaleMax).format("MM-DD-YYYY HH:mm:ss")
                  }}</span>
                </div>
              </div>
              <div v-if="logType == 'depth'" class="flex">
                <div class="block">
                  <div :style="{ rowGap: '0.1px' }" class="flex flex-col">
                    <div
                      class="mt-0 flex flex-col text_xs"
                      style="
                        font-size: 0.6rem;
                        color: var(--textColor);
                        font-weight: var(--fontWeight);
                        margin: auto;
                      "
                    >
                      <span>SCALE DEPTHs</span>
                    </div>
                    <div class="ml-0.5 mb-0.5 tool_section newStWDepth">
                      <input
                        :style="{ height: '12px !important' }"
                        type="number"
                        :value="
                          !isNaN(this.scaleMin) ? this.scaleMin.toFixed(0) : 0
                        "
                        class="depth_pick newStWDeptInput"
                        @input="
                          ($event) => {
                            !isNaN($event.target.value) &&
                              selectStartFeet($event.target.value);
                          }
                        "
                        @keyup.enter="selectStartFeet($event.target.value)"
                      />
                    </div>

                    <div class="ml-0.5 tool_section w-full newStWDepth">
                      <input
                        :style="{ height: '12px !important' }"
                        type="number"
                        :value="
                          !isNaN(this.scaleMax) ? this.scaleMax.toFixed(0) : 0
                        "
                        @input="
                          ($event) => {
                            !isNaN($event.target.value) &&
                              selectEndFeet($event.target.value);
                          }
                        "
                        @keyup.enter="selectEndFeet($event.target.value)"
                        class="depth_pick newStWDeptInput"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="flex justify-center"></div>
        </div>
        <div
          class="flex"
          :class="darkDark !== 'white' ? 'dark_trend_mode' : 'light_trend-mode'"
          :style="{
            width: templateWidth + 'px',
            maxWidth: templateWidth + 'px',
            height: bheight + 'px',
            background: 'var(--widgetBg)',
            transition: 'var(--transition)',
          }"
        >
          <div v-if="trackConfig.tracks">
            <div class="flex h-full __label_boxes_container" ref="replayTrendRef">
              <div
                class="__label_boxes"
                v-for="(track, j) in trackConfig.tracks"
                :key="j"
              >
                <div
                  id="tempdiv"
                  v-for="(curve, i) in track"
                  :key="i"
                  class="relative flex track_config_border bg-opacity-99 __bg_label_boxes"
                  :class="getAlertClassOnCondition(curve || {})"
                  :style="{
                    width: singleTrackWidth - 0.5 + 'px',
                    marginRight: 0 + 'px',
                    height: 45 + 'px',
                  }"
                  :draggable="true"
                  @dragstart="
                    startDrag($event, {
                      name: 'child',
                      trackNbr: j,
                      color: 'green',
                      curveNbr: i,
                      title: curve,
                      origin: compId,
                    });

                    $event.stopPropagation();
                  "
                >
                  <div class="t_d_col_1">
                    <button
                      v-if="curve"
                      v-on:click="showProps1(j, i)"
                      id='j+"-"+i'
                      class="colorbox"
                      :style="{
                        backgroundColor: curve.color,
                      }"
                    />
                  </div>
                  <div
                    v-if="
                      curveProps[j + '-' + i] && curveProps[j + '-' + i]['show']
                    "
                    :class="'curve_pop_opened'"
                    :style="{ right: singleTrackWidth + 2 + 'px' }"
                  >
                    <Props
                      :width="120"
                      :height="100 + '%'"
                      :curve="curveProps[j + '-' + i]['curve']"
                      @closeIt="closeIt(j, i)"
                      @apply="setCurveProps(j, i, $event)"
                      @delete="deleteCurve(j, i, $event)"
                      :alertArray="this.alertArr"
                      :showManualScaleCheckbox="true"
                    />
                  </div>
                  <div
                    v-if="curve"
                    class="name_numaric_con flex flex-col justify-center tooltip__tip top"
                    :class="
                      this.darkDark != 'white'
                        ? ' tooltip__tip_dark'
                        : ' tooltip__tip_light'
                    "
                    :data-tip="getLatestValuesToDisplay(curve)"
                  >
                    <div
                      style="paddingleft: 0.8px"
                      class="td_trnk"
                      :class="
                        ['activitycode', 'alert', 'washoutindex'].includes(
                          curve.name
                        )
                          ? 'colorBox_text_gray margin_top_title'
                          : 'colorBox_text_gray '
                      "
                    >
                      <!-- {{ curve.fullName.toUpperCase() }} -->
                      <!-- {{
                        curve.editedName ? curve.editedName : curve.full_name
                      }} -->
                      {{ getCrveNameFilter(curve) }}
                    </div>
                    <div
                      class="leading-none value_of_Mnemonic_in_DIS flex justify-center"
                    >
                      <p class="truncate">
                        {{ getLatestValuesToDisplay(curve) }}
                        &nbsp;
                      </p>
                    </div>
                    <div
                      class="font_xs flex justify-around rages_of_mnemonics_in_DIS"
                      v-if="
                        !['activitycode', 'alert', 'washoutindex'].includes(
                          curve.name
                        )
                      "
                    >
                      <p>{{ returningOfScale(curve) }}&nbsp;</p>
                      <p>
                        {{ curve.unit }}
                      </p>
                      <p>&nbsp;{{ returningOfScale1(curve) }}</p>
                    </div>
                  </div>
                  <div v-else class="mx-auto">
                    <i class="fa-solid fa-plus"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- live btn -->
      <Teleport v-if="!isReplay" :to="'#teleportFromTrendsLiveBtn' + displayId">
        <div class="block">
          <div class="ml-0.5 tool_section live_section_btn">
            <div
              :class="status == 'UPDATING' ? '' : 'tooltip__tip_live top'"
              :data-tip="status === 'UPDATING' ? 'Live' : 'No live data'"
            >
              <button
                :class="status == 'UPDATING' ? '' : 'disabled '"
                v-if="logType == 'time' || logType == 'depth'"
                @click="makeItLive"
                :disabled="liveDisabled"
                class="button-depth noselect text_xs float-right ml-2 newStylesLiveBtn"
                :style="
                  liveDisabled
                    ? 'color: var(--liveBtnGreen);' +
                      (this.logType == 'depth' ? '' : '')
                    : '' + (this.logType == 'depth' ? '' : '')
                "
              >
                <div
                  class="liveBtnDot"
                  :style="
                    liveDisabled === true
                      ? 'background:#ffffff'
                      : 'background:gray'
                  "
                ></div>
                LIVE
              </button>
            </div>
          </div>
        </div>
      </Teleport>

      <div
        id="distrenddiv"
        class="flex justify-start"
        :style="{ width: width + 'px' }"
      >
        <div
          class="flex"
          :class="darkDark == true ? 'bg-black' : 'light_trend-mode'"
          :style="{
            height: singleTrackHeight + 'px',
            width: templateWidth + 'px',
          }"
        >
          <div
            :style="{
              width: sliderWidth + 'px',
              height: wh.height - bheight - sliderWidth + 'px',
            }"
            :id="compId + 'localSliderParent'"
            class="slider-container flex relative noUi-target_dup"
          >
            <input
              v-if="wellId"
              :style="{
                top: -sliderWidth / 2 + 'px',
                left: sliderWidth / 2 + 'px',
                width: wh.height - bheight - sliderWidth + 'px',
                height: sliderWidth + 'px',
              }"
              @input="onInputSliderHandler"
              @change="onChangeSliderHandler"
              id="range1"
              type="range"
              name="range1"
              :min="this.scaleMinFixed"
              :max="this.scaleMaxFixed"
              step="1"
              :value="rangeInput"
            />
          </div>
          <div
            class="scrolldiv bg-opcity-95 flex"
            :class="
              darkDark !== 'white' ? 'bg-black_mattie' : 'light_trend-mode'
            "
            :style="{
              height: singleTrackHeight + 'px',
              width: templateWidth + 'px',
              maxWidth: width + 'px',
            }"
            :id="div_id"
            @drop="onDrop($event, 1)"
          ></div>
        </div>
      </div>
    </div>
    <div v-if="isShowTooltip && mouse_y_tooltip > 0" class="pointer-events-none">
      <div class="fixed w-full pointer-events-none" style="background: var(--textColor); height: 1px;"
        :style="{
          left: (this.$refs.replayTrendRef.getBoundingClientRect().left || 0) + (this.isDisProps ? 5 : 0) + 'px',
          top: tooltip_horizontalline_ypos + 'px',
          width: trackConfig.tracks.length ? (singleTrackWidth * trackConfig.tracks.length) + (5*trackConfig.tracks.length) - 2 + 'px' : '100%',
        }"
      ></div>
      <div
        class="absolute track_config_border_tooltip"
        :style="{
          width: singleTrackWidth * 0.98 + 'px',
          overflow: 'hidden',
          backgroundColor:
            this.darkDark != 'white'
              ? 'rgba(255,255,255,0.9)'
              : 'rgba(51,65,85,0.9)',
          top: this.mouse_y_tooltip + 'px',
          minHeight: '4.5em',
          // left:
          //   10 +
          //   this.singleTrackWidth * j +
          //    5 +
          //   sliderWidth +
          //   leftbarWidth +
          //   'px',
          left:
            leftbarWidth +
            sliderWidth +
            singleTrackWidth * j +
            (j + 1) * 5 +
            'px',
        }"
        v-for="(track, j) in trackConfig.tracks"
        :key="j"
      >
        <p
          class="relative"
          :style="{
            width: singleTrackWidth + 'px',
            textAlign: 'left',
            marginLeft: '10%',
            fontSize: '0.8em',
          }"
        >
          {{
            logType == "time"
              ? this.moment(this.tooltip_d["time"]).format("HH:mm:ss")
              : this.tooltip_d["dmea"]
          }}
        </p>
        <p
          v-for="(curve, i) in track"
          :key="i"
          class="flex"
          :style="{
            width: singleTrackWidth + 'px',
            textAlign: 'left',
            marginLeft: '10%',
            fontSize: '0.7rem',
          }"
        >
          <span
            :style="{
              color: curve.color,
              fontSize: '3.5em',
              lineHeight: '0rem',
              marginTop: '0.4rem',
            }"
          >
            &#8226;
          </span>
          &nbsp;{{
            this.tooltip_d[curve.name] ? this.tooltip_d[curve.name] : 0
          }}
        </p>
      </div>
    </div>
    <div
      v-if="this.isShowAlerts"
      class="block tool_group"
      :style="{
        top: this.mouse_y + 'px',
        left: this.mouse_x + 'px',
      }"
    >
      <div
        v-for="i in alertAnnotations"
        :key="i"
        class="px-1 py-1 border-green-700 text-green-700 tooltip_actc"
      >
        <template v-if="i.alert_name == hoveringAlert">
          <span class="tooltiptext">
            <div class="grid-container">
              <div class="item1 _item_">
                <div class="flex">
                  <div class="left_width font_xs">
                    <div class="flex justify-between">
                      <p class="ann_head">
                        {{ i.alert_name }}
                        <br />
                      </p>
                    </div>
                    <ul class="ann_para">
                      <div
                        class="annotation_desc"
                        v-html="JSON.parse(i.description).description1"
                      ></div>
                      <div
                        class="annotation_desc"
                        v-html="JSON.parse(i.description).description2"
                      ></div>

                      <div
                        class="annotation_desc"
                        v-html="JSON.parse(i.description).description3"
                      ></div>
                    </ul>
                  </div>
                </div>
              </div>
              <!-- <div class="item2 _item_">
                <div class="flex ...">
                  <div class="left_width font_xs">
                    <ul class="ann_para">

                    </ul>
                  </div>
                </div>
              </div> -->
            </div>
          </span>
        </template>
      </div>
    </div>

    <div
      v-if="this.isShowAlerts"
      class="block tool_group"
      :style="{
        top: this.mouse_y_clicked + '%',
        right: this.mouse_x_clicked + '%',
      }"
    >
      <div
        v-for="i in alertAnnotations"
        :key="i"
        class="px-1 py-1 border-green-700 text-green-700 tooltip_actc"
      >
        <template v-if="i.alert_name == clickedAlert">
          <span class="tooltiptext">
            <div class="grid-container">
              <div class="item1 _item_">
                <div class="flex">
                  <div class="left_width font_xs">
                    <div class="flex justify-between">
                      <p class="ann_head">
                        {{ i.alert_name }}
                        <br />
                      </p>
                      <p
                        @click="clickedAlert = ''"
                        :class="!show ? '' : 'text-white'"
                        class="cursor-pointer"
                      >
                        <svg
                          @click="clickedAlert = ''"
                          class="crossToHide"
                          :class="{ lightIcon: darkDark == 'white' }"
                          xmlns="http://www.w3.org/2000/svg"
                          width="11"
                          height="11"
                          viewBox="0 0 10 10"
                          fill="none"
                        >
                          <path
                            @click="clickedAlert = ''"
                            d="M5.93996 5.00004L8.80662 2.14004C8.93216 2.01451 9.00269 1.84424 9.00269 1.66671C9.00269 1.48917 8.93216 1.31891 8.80662 1.19338C8.68109 1.06784 8.51083 0.997314 8.33329 0.997314C8.15576 0.997314 7.9855 1.06784 7.85996 1.19338L4.99996 4.06004L2.13996 1.19338C2.01442 1.06784 1.84416 0.997314 1.66663 0.997314C1.48909 0.997314 1.31883 1.06784 1.19329 1.19338C1.06776 1.31891 0.997231 1.48917 0.997231 1.66671C0.997231 1.84424 1.06776 2.01451 1.19329 2.14004L4.05996 5.00004L1.19329 7.86004C1.13081 7.92202 1.08121 7.99575 1.04737 8.07699C1.01352 8.15823 0.996094 8.24537 0.996094 8.33337C0.996094 8.42138 1.01352 8.50852 1.04737 8.58976C1.08121 8.671 1.13081 8.74473 1.19329 8.80671C1.25527 8.86919 1.329 8.91879 1.41024 8.95264C1.49148 8.98648 1.57862 9.00391 1.66663 9.00391C1.75463 9.00391 1.84177 8.98648 1.92301 8.95264C2.00425 8.91879 2.07798 8.86919 2.13996 8.80671L4.99996 5.94004L7.85996 8.80671C7.92193 8.86919 7.99567 8.91879 8.07691 8.95264C8.15815 8.98648 8.24528 9.00391 8.33329 9.00391C8.4213 9.00391 8.50844 8.98648 8.58968 8.95264C8.67092 8.91879 8.74465 8.86919 8.80662 8.80671C8.86911 8.74473 8.91871 8.671 8.95255 8.58976C8.9864 8.50852 9.00382 8.42138 9.00382 8.33337C9.00382 8.24537 8.9864 8.15823 8.95255 8.07699C8.91871 7.99575 8.86911 7.92202 8.80662 7.86004L5.93996 5.00004Z"
                            fill="white"
                          />
                        </svg>
                      </p>
                    </div>
                    <ul class="ann_para">
                      <div
                        class="annotation_desc"
                        v-html="JSON.parse(i.description).description1"
                      ></div>
                      <div
                        class="annotation_desc"
                        v-html="JSON.parse(i.description).description2"
                      ></div>

                      <div
                        class="annotation_desc"
                        v-html="JSON.parse(i.description).description3"
                      ></div>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </span>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { template } from "../../../js/vertical/template.js";
import plotConfig from "../../../js/vertical/wellconfig.js";
import * as d3 from "d3";
import Props from "../Trend/Trendprops.vue";
import CustomSelect from "../../Select/SelectComponent.vue";
import apiService from "../../../api/services";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import defaultRanges from "../../../js/ranges";
import moment from "moment-timezone";
import { useReplay } from "../../../composable/useReplay.js";
import helperServices from "../../../helper-services.js";

export default {
  name: "Trend",
  components: {
    Props,
    CustomSelect,
    Datepicker,
  },
  data() {
    return {
      tooltipUpdateIndex: null,
      alertTimes: [],
      zoomed_Y_Axis: null,
      tracks_left_position: [],
      tooltip_d: {},
      mouse_y_tooltip: 0,
      tooltip_horizontalline_ypos: null,
      sliderValue: 0,
      mnemonic_groups: {},
      alertArr: [
        "ERRATIC MSE",
        "DRLG INEFF.",
        "ERRATIC ROP",
        "STACKING WEIGHT",
        "PRESSURE SPIKE",
        "BIT IMPACT",
        "MUD MOTOR DAMAGE POSSIBLE",
        "MUD MOTOR DAMAGE PROBABLE",
        "MOTOR STALL PROBABLE",
        "DIFF STICK",
      ],
      hoveringAlert: "",
      clickedAlert: "",
      mouse_y_clicked: 25,
      mouse_x_clicked: 2,
      scaleMinDate: "",
      isZoom: false,
      isRerendering: false,
      moment: moment,
      zero: [
        "wob",
        "mse",
        "diff",
        "spm1",
        "spm2",
        "spm3",
        "spp",
        "tgpm",
        "mfop",
        "rpm",
        "mg1c",
        "simplifiedmse",
        "chkp",
      ],
      double: [
        "hkl",
        "bpos",
        "dbtm",
        "dmea",
        "rop",
        "tor",
        "drtm",
        "inc",
        "az",
        "tvd",
        "dens_in",
        "dens_out",
        "temp_in",
        "temp_out",
      ],
      mouse_x: 0,
      mouse_y: 0,
      isEraticMse: false,
      isMotorStalled: false,
      isMotorDamagePossible: false,
      isMotorDamageProbable: false,
      isPressSpike: false,
      isErraticRop: false,
      isStackingWeight: false,
      isDrillInEfficiency: false,
      isDiffPressAlert: false,
      isBitImpact: false,
      // Socket Variables:
      stopUpdation: false,
      isShowTooltip: false,
      isShowAlerts: false,
      depthMaxValue: 0,
      depthMinValue: 0,
      currentTimeRange: 1,
      currentFeetRange: 500,
      topic: null,
      socketId: null,
      socket: null,

      liveDisabled: false,
      timeRanges: [0.0833333, 0.1666666, 0.25, 0.5, 1, 2, 8, 12, 24],
      timeRangesNames: [
        "5 MINS",
        "10 MINS",
        "15 MINS",
        "30 MINS",
        "1 HRS",
        "2 HRS",
        "8 HRS",
        "12 HRS",
        "24 HRS",
      ],
      secondsdataavailability: false,
      focusAxisList: [],
      bgColor: "#202124",
      configurePlots: {},
      templateWidth: this.width - 10,
      div_id: "divId",
      tickColor: "#ededed",
      visible: false,
      sliderWidth: 100,
      sliderBG: "lightgray",
      readyToPlot: false,
      emptyPlot: true,
      currentCurve: null,
      selectedTrackValue: null,
      isNewTrack: false,
      curve: "",
      trackConfig: {},
      bheight: 138,
      wellId: null,
      wellboreId: null,
      logId: null,
      wellboreState: null,
      logType: null,
      data: [],
      completeData: [],
      zoomMin: null,
      zoomMax: null,
      plots: [],
      plotTemplate: template(""),

      sliderWidth: 50,
      leftbarWidth: 140,
      trackWidth: 300,
      scaleMin: 0,
      scaleMax: 1000,
      rangeInput: 0,
      scaleMinFixed: 0,
      scaleMaxFixed: 1000,
      singleTrackHeight: null,
      slider: null,
      singleTrackWidth: 120,
      totTracks: [],
      compId: "",
      curveProps: {},
      templateHeight: 0,
      tracksNbr: 6,
      tickThickness: 0.1,
      tracks: [],
      renderedHeight: false,
      renderedWidth: false,
      widthChanged: false,
      heightChanged: false,
      // wh: {
      //   width: this.width,
      //   height: this.height,
      // },
      feetRange: [50, 100, 500, 1000],
      feetRangeNames: [50, 100, 500, 1000],
      // hrsRange: ["default", 4, 8, 12, 24],
      feet: "",
      hrs: "",
      track1Id: "",
      track2Id: "",
      track3Id: "",
      track4Id: "",
      track5Id: "",
      isSpecialtyScreenCreated: false,
      updateTimeout: null,
      status: -1,
      active_popover: false,
      isActcBarHover: false,
      actcBarMessage: "",
      timer: -1,
      myDisWatcher: null,
      allow_to_change: null,
      isDis: false,
      replayInterval: null,
      replayDataInterval: 60,
      alertStickslip: [],
      alertWashout: [],
      logInterval:null
    };
  },
  props: {
    height: {
      type: [Number, String],
      default: "50",
    },
    width: {
      type: [Number, String],
      default: "50",
    },
    id: String,
    divid: String,
    displayId: String,
    plotConfigProps: {
      type: Object,
      default: {},
    },
    isReplay: {
      type: Boolean,
      require: false,
      default: false,
    },
    isDisProps: {
      type: Boolean,
      default: false,
    },
  },
  beforeUnmount() {
    this.data = null;
    this.completeData = null;
    this.mnemonic_groups = null;
    this.takeOffSocket();
    this.myDisWatcher();
  },
  async mounted() {
    this.isDis = this.isDisProps;
    this.$store.dispatch("data/getAlertAnnotations");
    // this.$store.dispatch("data/getAlertsList");
    if (this.isDisProps) {
      this.isDis = true;
      this.leftbarWidth = 120;
    }
    this.customer = this.$store.state.data.customer;
    this.compId = this.compId = Math.random().toString(36).slice(8);
    this.compId = this.compId;
    this.div_id = "id" + this.compId;
    this.div_id = this.div_id;
    let dtls = this.$store.state.disp.displays[this.displayId];

    this.myDisWatcher = this.$store.subscribe(async (mutation, state) => {
      if (
        mutation.type == "disp/setDisplay" &&
        this.displayId == mutation.payload.display &&
        this.id
      ) {
        this.zoomed_Y_Axis = null;
        this.leftbarWidth = 120;
        this.replayDataInterval = 60;
        this.tooltip_horizontalline_ypos = null;
        this.timer = -1;
        this.takeOffSocket();
        this.data = [];
        this.completeData = [];
        this.mnemonic_groups = {};
        this.wellId = mutation.payload.wellId;
        this.wellboreId = mutation.payload.wellboreId;
        this.logId = mutation.payload.logId;
        this.secondsdataavailability = mutation.payload.secondsdataavailability;
        this.logType =
          mutation.payload.logType == "trajectory"
            ? "time"
            : mutation.payload.logType;
        this.logTypeId = mutation.payload.logTypeId;
        this.status = mutation.payload.status;
        this.topic = mutation.payload.topicName;
        this.time_zone = mutation.payload.time_zone || "CST6CDT";
        this.logInterval = mutation?.payload?.well_interval || null;
        
        moment.tz.setDefault(this.time_zone);
        // console.log("~~~ time zone alert : mutatiion ", this.time_zone);
        let behaviour = "drag";
        let format = {};

        if (this.logType == "time") {
          let minMax = await this.getMinMaxRange();
          if (this.isReplay) {
            this.scaleMin = minMax.min;
            // this.timeRanges = [4];
            this.currentTimeRange = 1;
            this.scaleMinFixed = minMax.min;
            this.scaleMaxFixed = minMax.max;
            this.scaleMax = minMax.min + 3600000 * 1;
            this.rangeInput = 0;
            if(this.logInterval && this.logInterval=='5_sec'){
              this.replayDataInterval=60/5;
            }
          } else {
            this.currentTimeRange = 1;
            this.scaleMin =
              minMax.max - 3600000 > minMax.min
                ? minMax.max - 3600000
                : minMax.min;
            this.scaleMax =
              minMax.max - 3600000 > minMax.min
                ? minMax.max
                : minMax.min + 3600000;
            this.scaleMinFixed = minMax.min;
            this.scaleMaxFixed = minMax.max;
          }
          this.scaleMinDate = new Date(this.scaleMin).toLocaleString("en-US", {
            timeZone: this.time_zone,
          });
          this.scaleMaxDate = new Date(this.scaleMax);
          if (minMax.min > minMax.max) {
            this.data = {};
            this.completeData = {};
            this.rerender();
            this.$toast.error("Start time can not be greater than End time.", {
              position: "top",
              duration: 3000,
            });
            // return false;
          }
        } else {
          this.currentFeetRange = 500;

          let minMax = await this.getMinMaxRange();
          if (minMax.max == minMax.min || minMax.max - 500 < minMax.min)
            minMax.max = minMax.min + 1000;
          this.scaleMin = minMax.max - 500;

          this.scaleMax = minMax.max;
          this.rangeInput = 0;
          this.scaleMinFixed = minMax.min;
          this.scaleMaxFixed = minMax.max;
        }

        this.trackPropsMapping();
        if (!this.isReplay) {
          if (this.status == "UPDATING") {
            let timeout_var_livedata = setTimeout(() => {
              clearTimeout(timeout_var_livedata);
              this.getRealtimeData();
            }, 600);
          } else {
          }
        }
        let noDIV = d3
          .select("#" + this.div_id)
          .selectAll("div")
          .remove();
        let noSVG = d3
          .select("#" + this.div_id)
          .selectAll("svg")
          .remove();
        this.zoomIt(
          this.scaleMax,
          this.scaleMin,
          this.feet,
          true,
          "update 140989"
        );
      }
    });
    let format = {};
    if (
      dtls != null &&
      dtls.wellId != null &&
      dtls.wellboreId != null &&
      dtls.wellboreState != null
    ) {
      this.replayDataInterval = 60;
      this.tooltip_horizontalline_ypos = null;
      this.wellId = this.wellId = dtls.wellId;
      this.wellboreId = this.wellboreId = dtls.wellboreId;
      this.logId = this.logId = dtls.logId;
      this.secondsdataavailability = dtls.secondsdataavailability;
      this.wellboreState = this.wellboreState = dtls.wellboreState;
      this.status = dtls.status;
      this.logType = dtls.logType == "trajectory" ? "time" : dtls.logType;
      this.logTypeId = dtls.logTypeId;
      this.topic = dtls.topicName;
      this.time_zone = dtls.time_zone || "CST6CDT";
      this.logInterval = dtls?.well_interval || null;

      // console.log("~~~ time zone alert : mounted ", this.time_zone);
      moment.tz.setDefault(this.time_zone);
      let minMax = await this.getMinMaxRange();

      if (this.logType == "time") {
        if (this.isReplay) {
          this.scaleMin = minMax.min;
          // this.timeRanges = [4];
          this.currentTimeRange = 1;
          this.scaleMinFixed = minMax.min;
          this.scaleMaxFixed = minMax.max;
          this.scaleMax = minMax.min + 3600000 * 1;
          this.rangeInput = 0;
          if(this.logInterval && this.logInterval=='5_sec'){
          this.replayDataInterval=60/5;
        }
        } else {
          this.scaleMin =
            minMax.max - 3600000 > minMax.min
              ? minMax.max - 3600000
              : minMax.min;
          this.scaleMax =
            minMax.max - 3600000 > minMax.min
              ? minMax.max
              : minMax.min + 3600000;
          this.scaleMinDate = new Date(this.scaleMin).toLocaleString("en-US", {
            timeZone: this.time_zone,
          });
          this.scaleMaxDate = new Date(this.scaleMax);
        }
      } else {
        let minMax = await this.getMinMaxRange();
        if (minMax.max == minMax.min || minMax.max - 500 < minMax.min)
          minMax.max = minMax.min + 1000;
        this.scaleMin = minMax.max - 500;

        this.scaleMax = minMax.max;
        this.scaleMinFixed = minMax.min;
        this.scaleMaxFixed = minMax.max;
      }
      this.rangeInput = 0;
      if (minMax.min > minMax.max) {
        this.data = {};
        this.completeData = {};
        this.rerender();
        this.$toast.error("Start time can not be greater than End time.", {
          position: "top",
          duration: 3000,
        });
        // return false;
      }
      this.trackPropsMapping();
    } else {
    }
    if (this.trackConfig.tracks)
      this.setTrackWidth(this.trackConfig.tracks.length);
    else this.setTrackWidth(0);
    this.setTrackHeight();
    this.zoomIt(this.scaleMax, this.scaleMin, this.feet, false, "update 14091");
  },
  computed: {
    wh() {
      let wh = {};
      wh.width = this.width;
      wh.height = this.height;
      wh.templateWidth = this.width - 10;
      wh.templateHeight = this.height - 10;
      this.templateHeight = this.height - 10;
      this.templateWidth = this.width - 10;

      // this.rerender('WH');

      return wh;
    },
    darkDark() {
      d3.selectAll(".y2").style(
        "fill",
        this.$store.state.rect.darkmode !== "white" ? "white" : "black"
      );
      setTimeout(() => {
        this.rerender("darkDark");
      }, 0);
      return this.$store.state.rect.darkmode;
    },
    alertAnnotations() {
      return this.$store.state.data.annotations;
    },
    isActive(){
      return this.$store.state.disp.rects.find(r=>r.displayId==this.displayId)?.isMinimize;
    }
  },
  sockets: {
    connect: function () {},
    disconnect(data) {
      console.log("data : disconnect : ", data);
      this.$socket.emit("unsubscribe", {
        logId: `${this.wellId}~**~=~**~${this.wellboreId}~**~=~**~${this.logType}`,
        topic: this.topic,
        id: this.id,
        clientId: this.$clientId,
      });
    },
    error(data) {
      console.log("data : disconnect : ", data);
    },
    reconnect() {
      this.$socket.emit("subscribe", {
        logId: `${this.wellId}~**~=~**~${this.wellboreId}~**~=~**~${this.logType}`,
        topic: this.topic,
        fields: this.fields,
        id: this.id,
        clientId: this.$clientId,
      });
    },
    reconnect_failed() {},
    reconnect_error() {},
    connect_error() {},
    connecting() {},
    connect_timeout() {},
  },
  methods: {
    getCrveNameFilter(curve) {
      let name = curve.editedName
        ? curve.editedName
        : curve.full_name
        ? curve.full_name
        : curve.name
        ? curve.name.toUpperCase()
        : "";
      return name == "ACTIVITYCODE" ? "RIG STATE" : name;
    },
    clickZoomHandler(isZoomIn) {
      let tempScaleMin = 0;
      let tempScaleMax = 0;
      let zoomAmount = parseInt((this.currentTimeRange * 3600 * 1000) / 8);
      console.log(
        "🚀 ➽ file: DisTrend.vue:1009  ➽ clickZoomHandler  ➽ zoomAmount ⏩",
        zoomAmount
      );
      if (this.logType == "depth") {
        zoomAmount = parseInt(this.currentFeetRange / 8);
      }
      if (isZoomIn) {
        tempScaleMin = this.scaleMin + zoomAmount;
        tempScaleMax = this.scaleMax - zoomAmount;
      } else {
        tempScaleMin = this.scaleMin - zoomAmount;
        tempScaleMax = this.scaleMax + zoomAmount;
      }

      if (
        tempScaleMax != tempScaleMin &&
        tempScaleMax > tempScaleMin &&
        this.scaleMax > this.scaleMin &&
        tempScaleMax <= this.scaleMaxFixed &&
        this.scaleMinFixed <= tempScaleMin
      ) {
        this.scaleMax = tempScaleMax;
        this.scaleMin = tempScaleMin;
        this.zoomIt(
          this.scaleMax,
          this.scaleMin,
          this.feet,
          false,
          "clickZoomHandler"
        );
      } else {
        this.$toast.info("Maximum Zoomed", {
          position: "top",
          duration: 1000,
        });
      }
    },
    onInputSliderHandler(event) {
      // console.log("🚀 ➽ file: DisTrend.vue:914  ➽ onInputSliderHandler  ➽ event ⏩" , event.target.value)
      this.sliderValue = event.target.value;
      if (this.logType == "depth") {
        if (event.target.value <= this.scaleMinFixed + this.currentFeetRange) {
          this.scaleMin = this.scaleMinFixed;
          this.scaleMax = this.scaleMinFixed + this.currentFeetRange;
        } else {
          this.scaleMin = event.target.value - this.currentFeetRange;
          this.scaleMax = parseInt(event.target.value);
        }
        // this.scaleMin = event.target.value - this.currentFeetRange;
        // this.scaleMax = parseFloat(event.target.value);
        this.zoomIt(
          this.scaleMax,
          this.scaleMin,
          this.feet,
          false,
          "onInputSliderHandler"
        );
      }
    },
    onChangeSliderHandler(event) {
      // document.querySelector("#slider_value").innerHTML = event.target.value;
      console.log(
        "🚀 ➽ file: DisTrend.vue:913  ➽ onChangeSliderHandler  ➽ event.target.value ⏩",
        event.target.value
      );
      if (this.liveDisabled) {
        this.takeOffSocket();
        this.liveDisabled = false;
      }
      if (this.logType == "time") {
        if (
          event.target.value <=
          this.scaleMinFixed + this.currentTimeRange * 3600000
        ) {
          this.scaleMin = this.scaleMinFixed;
          this.scaleMax = this.scaleMinFixed + this.currentTimeRange * 3600000;
        } else {
          this.scaleMin = event.target.value - this.currentTimeRange * 3600000;
          this.scaleMax = parseInt(event.target.value);
        }
        this.rangeInput = event.target.value;
        this.zoomIt(
          this.scaleMax,
          this.scaleMin,
          this.feet,
          false,
          "onChangeSliderHandler"
        );
      }
    },
    getAlertClassOnCondition(curve) {
      let name = curve.name;
      let alert = curve.alert;
      if (curve.isAlert) {
        // console.log("alert in get alert class", alert);
        if (
          name == "alert" ||
          name == "washoutindex" ||
          name == "activitycode"
        ) {
          return "";
        } else {
          if (this.data[name] && this.data[name][this.data[name].length - 1]) {
            let value = this.data[name][this.data[name].length - 1];
            if (
              !isNaN(value) &&
              alert &&
              !isNaN(alert[0]) &&
              !isNaN(alert[1])
            ) {
              if (alert[0] >= value || alert[1] <= value) {
                return "___quadrat";
              } else {
                return "";
              }
            } else {
              return "";
            }
          } else {
            return "";
          }
        }
      } else {
        return "";
      }
    },
    async getFullNameDisplay(name) {
      try {
        console.log("name : ", name);
        name = name.name;
        let mapping = await helperServices.getIndexDetails(this.wellId);
        mapping = mapping.data.logs[this.logType];
        // console.log("get full name display : ", mapping);
        if (name == "diff") return "DIFF PRESS";
        else if (name == "simplifiedmse") return "MSE";
        else if (name == "bpos") return "BLOCK POS";
        else if (name == "stickslipindex") return "STICK SLIP";
        else if (name == "washoutindex") return "WASH OUT";
        else if (name == "alert") return "ALERTS";
        else if (name == "depthofcut") return "DEPTH OF CUT";
        else
          return mapping.displayTags[mapping.tags.indexOf(name)].toUpperCase();
      } catch (error) {
        return "";
      }
    },
    getLatestValuesToDisplay(curve) {
      let name = curve.name;
      let value = "0";
      let decimals = curve.decimals || 0;
      if (this.data[name] && this.data[name][this.data[name].length - 1]) {
        let value = this.data[name][this.data[name].length - 1];
        if (!isNaN(value)) {
          if (name == "activitycode") {
            let values = {
              1: "ROT DRLG",

              2: "SLD DRLG",

              3: "CIRC WITH ROT",

              4: "UNKNOWN",

              10: "CIRCULATION",

              11: "REAMING",

              12: "BACKREAM",

              13: "CONNECTION",

              14: "TIH-DRLG",

              15: "POOH-DRLG",

              16: "CIRC WITH ROT",

              17: "CIRCULATION",

              30: "STATIONARY",

              5: "TIH",

              6: "POOH",

              20: "PUMPOUT",

              21: "REAM-TRIP",

              22: "BACK REAM-TRIP",

              23: "CONN.TRIP",

              24: "CIRC WITH ROT-TRIP",

              25: "CIRC W/O ROT-TRIP",

              26: "CIRCULATION",

              27: "WASHDOWN",
            };
            return values[value];
          } else return Number(value).toFixed(decimals);
        } else if (name == "alert" && value) {
          return value;
        } else if (name == "washoutindex") {
          return "";
        }
      } else {
        if (
          name == "alert" ||
          name == "washoutindex" ||
          name == "activitycode"
        ) {
          return "";
        } else {
          return value;
        }
      }
      // this.data[name] && this.data[name][this.data[name].length - 1]
      //   ? (name == "alert" && this.data[name][this.data[name].length - 1]) ||
      //     this.data[name][this.data[name].length - 1].toFixed(2)
      //   : "0";
    },
    returningOfScale1(curve) {
      try {
        return curve.scale[1] ? Number(curve.scale[1]).toFixed(2) : 0.0;
      } catch (error) {
        // console.log(
        //   "🚀 ➽ file: DisTrend.vue:1044  ➽ returningOfScale1  ➽ error ⏩",
        //   error
        // );
        return 0;
      }
    },
    returningOfScale(curve) {
      try {
        return curve.scale[0] ? Number(curve.scale[0]).toFixed(2) : "0.00";
      } catch (error) {
        // console.log(
        //   "🚀 ➽ file: DisTrend.vue:1052  ➽ returningOfScale  ➽ error ⏩",
        //   error
        // );
        return 0;
      }
    },
    selectStartFeet(feet) {
      feet = parseInt(feet);
      if (feet < this.scaleMax) {
        this.scaleMin = feet;
        this.zoomIt(
          this.scaleMax,
          this.scaleMin,
          this.feet,
          false,
          "update 14092"
        );
      } else {
        this.$toast.error("Invalide Depth Selection", {
          position: "top",
          duration: 1000,
        });
      }
    },
    selectEndFeet(feet) {
      feet = parseInt(feet);
      if (feet > this.scaleMin) {
        this.scaleMax = feet;
        this.zoomIt(
          this.scaleMax,
          this.scaleMin,
          this.feet,
          false,
          "update 14093"
        );
      } else {
        this.$toast.error("Invalide Depth Selection", {
          position: "top",
          duration: 1000,
        });
      }
    },
    onFromDateChange: function (e) {
      if (e) {
        if (this.isReplay) {
          this.scaleMinDate = e;
          // const newTime = moment(e).unix() * 1000;
          const newTime =
            moment(
              new Date(this.scaleMinDate).toISOString().slice(0, 16)
            ).unix() *
              1000 -
            new Date().getTimezoneOffset() * 60 * 1000;
          this.changeDateReplay(newTime);
          this.data = [];
          this.completeData = {};
        } else {
          this.takeOffSocket();
          this.scaleMinDate = e;
          console.log(
            "date trend : ",
            e,
            moment(this.scaleMinDate).format("HH:MM:SS mm:ss")
          );
          let timeDifference = this.scaleMax - this.scaleMin;
          this.scaleMax = moment(this.scaleMinDate).unix() * 1000;
          this.scaleMin = this.scaleMax - timeDifference;
          this.zoomIt(
            this.scaleMax,
            this.scaleMin,
            this.feet,
            false,
            "update 14094"
          );
        }
      }
    },
    alertValueChange(value, alert) {
      alert = alert.replace(/ /g, "");

      if (this.isShowAlerts && value) {
        this.hoveringAlert = alert;
      }
    },
    alertValueClicked(value, alert) {
      alert = alert.replace(/ /g, "");
      if (this.isShowAlerts) {
        this.clickedAlert = alert;
      } else {
        this.clickedAlert = "";
      }
    },
    getRandomColor() {
      let letters = "0123456789ABCDEF";
      let color = "#";
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    },
    takeOffSocket() {
      this.timer = -1;
      this.liveDisabled = false;
      if (this.$socket) {
        this.$socket.emit("unsubscribe", {
          logId: `${this.wellId}~**~=~**~${this.wellboreId}~**~=~**~${this.logType}`,
          topic: this.topic,
          id: this.id,
          clientId: this.$clientId,
        });
        this.sockets.unsubscribe(this.$clientId);
      }
      this.stopUpdation = false;
    },
    async trackPropsMapping() {
      let saved = false;

      if (
        this.plotConfigProps.tracks !== undefined &&
        this.plotConfigProps.tracks !== null
      ) {
        this.trackConfig = this.plotConfigProps;
        saved = true;
        this.trackConfig = {};

        let tracks = this.plotConfigProps.tracks;
        this.templateWidth = this.width;
        let mnemonics = [];

        for (let i = 0; i < tracks.length; i++) {
          let tags = tracks[i];
          for (let j = 0; j < tags.length; j++) {
            let trackId = "t" + Math.random().toString(36).slice(8);
            tags[j].trackId = trackId;
            mnemonics.push(tags[j].name);
          }
          tracks[i] = tags;
        }
        tracks.forEach((track) => {
          track.map((eachMn) => {
            eachMn["description"] = eachMn.fullName;
          });
        });
        this.plotConfigProps.tracks = tracks;
        this.trackConfig = this.plotConfigProps;
        this.tracks = tracks;
        // console.log("tracks::::::::::::::", tracks);
        // await this.getMultiTagsData(mnemonics, 'trackPropsMapping');
        // this.adjustTracks();
        // this.rerender('trackPropsMapping');
      } else {
        this.trackConfig = this.plotConfigProps;
      }
    },
    getOnScreenMnemonics() {
      let tracks = this.trackConfig.tracks;
      let mnemonics = [];
      if (tracks) {
        for (let i = 0; i < tracks.length; i++) {
          let tags = tracks[i];
          for (let j = 0; j < tags.length; j++) {
            // let trackId = "t" + Math.random().toString(36).slice(8);
            // tags[j].trackId = trackId;

            let mn_name =
              tags[j].name == "DBTM"
                ? tags[j].name.toLowerCase()
                : tags[j].name;
            mnemonics.push(mn_name);

            mnemonics.push("time");
            // this.getMnemonicType(mn_name);
          }
          tracks[i] = tags;
        }
      }
      let extra = ["stickslip", "washout", "dmea", "alert"];
      for (let a of extra) {
        if (
          mnemonics.indexOf("stickslipindex") > -1 &&
          mnemonics.indexOf("stickslip") == -1
        ) {
          mnemonics.push(a);
          // this.getMnemonicType(a);
        }

        if (
          mnemonics.indexOf("washoutindex") > -1 &&
          mnemonics.indexOf("washout") == -1
        ) {
          mnemonics.push(a);
          // this.getMnemonicType(a);
        }

        if (mnemonics.indexOf("dmea") == -1) {
          mnemonics.push(a);
          // this.getMnemonicType(a);
        }
        if (mnemonics.indexOf("alert") == -1) {
          mnemonics.push(a);
          // this.getMnemonicType(a);
        }
      }

      mnemonics = [...new Set(mnemonics)];

      return mnemonics;
    },
    async getMnemonicType(mnemonic) {
      console.time("get mnemonic type", mnemonic);
      console.log(" mnemonic : ", Array.isArray(mnemonic));
      let mnemonic_groups = {};
      if (this.wellId) {
        let logDetails = await helperServices.getIndexDetails(this.wellId);
        console.log("logdetails:", logDetails);
        let all_mnemonics = logDetails.data.logs[this.logType].mnemonics;
        const source_mnemonice = logDetails.data.logs[this.logType].source;
        const tags = logDetails.data.logs[this.logType].tags;
        let mnemonic_type = "UNPREFERRED";

        if (Array.isArray(mnemonic)) {
          for (let tag of mnemonic) {
            const mnemonicKey = tags.findIndex((t) => t == tag);
            const actualKey = source_mnemonice[mnemonicKey];
            for (let i in all_mnemonics) {
              if (!actualKey) {
                mnemonic_type = "SOLUTION";
                break;
              }
              if (all_mnemonics[i][actualKey]) {
                mnemonic_type = i;
                mnemonic_groups[i] = [];
                break;
              }
            }
            if (!this.mnemonic_groups[mnemonic_type])
              this.mnemonic_groups[mnemonic_type] = [];
            if (this.mnemonic_groups[mnemonic_type].indexOf(tag) == -1)
              this.mnemonic_groups[mnemonic_type] = [
                ...this.mnemonic_groups[mnemonic_type],
                tag,
              ];
          }
          console.log(" mnemonic_groups ", this.mnemonic_groups);
          delete this.mnemonic_groups["UNPREFERRED"];
          // delete this.mnemonic_groups["MISC"]
          return mnemonic_type;
        } else {
          const mnemonicKey = tags.findIndex((t) => t == mnemonic);
          const actualKey = source_mnemonice[mnemonicKey];
          console.log(" actualKey ", tag, actualKey);
          for (let i in all_mnemonics) {
            if (!actualKey) {
              mnemonic_type = "SOLUTION";
              break;
            }
            if (all_mnemonics[i][actualKey]) {
              mnemonic_type = i;
              mnemonic_groups[i] = [];
              break;
            }
          }
          console.log(" actualKey ", mnemonic_type);
          if (!this.mnemonic_groups[mnemonic_type])
            this.mnemonic_groups[mnemonic_type] = [];
          if (this.mnemonic_groups[mnemonic_type].indexOf(mnemonic) == -1)
            this.mnemonic_groups[mnemonic_type] = [
              ...this.mnemonic_groups[mnemonic_type],
              mnemonic,
            ];
          return mnemonic_type;
        }
      }
    },
    async getMultiTagsData(mnemonics, from) {
      this.$store.dispatch("data/startLoading");
      let result = null;
      if (mnemonics.indexOf("dmea") == -1) mnemonics.push("dmea");
      if (mnemonics.indexOf("alert") == -1) mnemonics.push("alert");
      await this.getMnemonicType(mnemonics);
      try {
        if (this.logType == "time") {
          for (let i in this.mnemonic_groups) {
            result = await apiService.WellServices.getTimeLogs({
              fields: this.mnemonic_groups[i],
              well_id: this.wellId,
              wellbore_name: this.wellboreId,
              secondsdataavailability: this.secondsdataavailability,
              // days: 0.2,
              type: i,
              from: this.scaleMax,
              to: this.scaleMin,
              topic:this.$store.state.disp?.displays[this.displayId]?.topicName
            });

            if (result && result.data && result.data.data) {
              if (
                result.data.data[this.mnemonic_groups[i][0]] &&
                result.data.data[this.mnemonic_groups[i][0]].length == 0
              ) {
                // this.$toast.error("Data is not available for this interval", {
                //   position: "top",
                //   duration: 3000,
                // });
              } else {
                this.completeData = {
                  ...this.completeData,
                  ...result.data.data,
                };
                this.data = this.completeData;
                this.minmax = { ...this.minmax, ...result.data.minmax };
              }
            } else {
              // this.$toast.error("Server is taking longer than usual", {
              //   position: "top",
              //   duration: 3000,
              // });
            }
          }
        } else {
          apiService.WellServices.getDepthLogs({
            fields: mnemonics,
            well_id: this.wellId,
            wellbore_name: this.wellboreId,
            // isPartial: false,
          })
            .then(async (data) => {
              if (data && data.data && data.data.data) {
                this.completeData = { ...this.completeData, ...data.data.data };
              }
            })
            .catch((e) => {
              console.error("whole data error : ", e);
            });
          result = await apiService.WellServices.getDepthLogs({
            fields: mnemonics,
            well_id: this.wellId,
            wellbore_name: this.wellboreId,
            isPartial: true,
            start: this.scaleMin,
            end: this.scaleMax,
          });

          // result = result.data;
          // for (let i of mnemonics) {
          //   await this.addData(result, i);
          // }
          if (result && result.data && result.data.data) {
            if (
              result.data.data["dmea"] &&
              result.data.data["dmea"].length == 0
            ) {
              this.$toast.error("Data is not available for this well", {
                position: "top",
                duration: 3000,
              });
            }
          }
          this.completeData = { ...this.completeData, ...result.data.data };
          this.data = { ...this.data, ...result.data.data };
          this.minmax = { ...this.minmax, ...result.data.minmax };
        }
      } catch (error) {
        console.error(error);
      }

      setTimeout(() => {
        this.$store.dispatch("data/stopLoading");
      }, 700);
    },
    setContextIdWidget(e) {
      this.$store.dispatch("disp/setContextId", {
        contextId: this.displayId,
        contextWidgetId: this.id,
        contextType: "widget",
      });
    },
    getRealtimeData() {
      if (this.isReplay) return;
      this.liveDisabled = true;
      let test = this.id;
      if (this.data === undefined || this.data === null) {
        this.data = [];
        this.mnemonic_groups = {};
      }
      let topic = this.topic;
      let wells = this.$store.state.data.wells;
      let well = wells[this.wellId];

      if (well) {
        let wellbore = well.wellbores[this.wellboreId];
        let log = wellbore["logs"][this.logType];
        this.logId = log.id;
        let fields = this.getOnScreenMnemonics();
        console.log("fields  : ", fields, log);
        fields.push("time");
        this.fields = fields;
        let topicExists = this.$store.state.live.isSubscribed[topic];
        if (this.wellId && this.wellboreId && this.logType) {
          this.$socket.emit("subscribe", {
            logId: `${this.wellId}~**~=~**~${this.wellboreId}~**~=~**~${this.logType}`,
            topic: this.topic,
            fields: this.fields,
            id: this.id,
            clientId: this.$clientId,
            logType: this.logType,
          });
          if (!topicExists) {
            this.$store.dispatch("live/setSubscriptionFlag", {
              topic,
              flag: true,
            });
          }

          this.sockets.subscribe(this.$clientId, (sResult) => {
            try {
              let result = sResult[this.id];
              if (result) {
                let isLiveDepth = false;
                if (
                  this.logType == "depth" &&
                  this.completeData["dmea"] &&
                  this.completeData["dmea"].length &&
                  result["dmea"] &&
                  result["dmea"].length
                ) {
                  if (
                    this.completeData["dmea"][
                      this.completeData["dmea"].length - 1
                    ] < result["dmea"][result["dmea"].length - 1]
                  )
                    isLiveDepth = true;
                }

                if (this.logType == "time") {
                  if (
                    // this.data["time"][this.data["time"].length - 1] <
                    // result["time"][result["time"].length - 1] &&
                    // this.data["time"][this.data["time"].length - 1] <
                    // result["time"][0]
                    true
                  )
                    for (let i in this.completeData) {
                      if (result[i]) {
                        if (this.logType == "time") {
                          this.completeData[i].splice(0, result[i].length);
                        }
                        this.completeData[i] = [
                          ...this.completeData[i],
                          ...result[i],
                        ];
                      }
                    }
                } else {
                  if (isLiveDepth)
                    for (let i in this.completeData) {
                      if (result[i]) {
                        this.completeData[i] = [
                          ...this.completeData[i],
                          ...result[i],
                        ];
                      }
                    }
                }
                if (this.logType == "time") {
                  this.data = this.completeData;
                  this.scaleMin = this.data["time"][0];
                  this.scaleMax =
                    this.data["time"][this.data["time"].length - 1];

                  // this.scaleMin = parseInt(this.scaleMin);
                  // this.scaleMax = parseInt(this.scaleMax);
                } else {
                  this.scaleMin +=
                    result["dmea"][result["dmea"].length - 1] - this.scaleMax;
                  if (this.scaleMin < 0) {
                    this.scaleMin = this.completeData["dmea"][0];
                  }
                  this.scaleMax = result["dmea"][result["dmea"].length - 1];
                  // this.scaleMin = parseInt(this.scaleMin);
                  // this.scaleMax = parseInt(this.scaleMax);
                }
                this.scaleMaxFixed = this.scaleMax;
                localStorage.setItem("time", this.scaleMaxFixed);
                // console.log(this.data );

                // console.log(
                //   "value of scalemax is smaller than scale Min : ",
                //   new Date(this.scaleMax).toISOString(),
                //   new Date(this.scaleMin).toISOString()
                // );

                if (this.scaleMax > this.scaleMin) {
                }
                // console.log("this.data[time']", this.data["time"].length)
                this.zoomIt(
                  this.scaleMax,
                  this.scaleMin,
                  this.feet,
                  false,
                  "update live"
                );
              }
            } catch (error) {
              console.error("error in socket subscribe : ", error);
            }
          });
        }
        topicExists = this.$store.state.live.isSubscribed[topic];
      }
    },
    selectFeet(feet) {
      let scaleMin = 0;
      this.currentFeetRange = feet;
      if (feet == "default") {
        this.feet = 1000;
        scaleMin = this.scaleMax - 1000;
      } else {
        this.feet = feet;
        scaleMin = this.scaleMax - feet;
      }

      this.scaleMin = scaleMin;
      this.zoomIt(
        this.scaleMax,
        this.scaleMin,
        this.feet,
        false,
        "update 14095"
      );
    },
    async makeItLive() {
      // it fetches the latest 4 hour of data and start the socket data stream

      if (this.isZoom) {
        this.isZoom = false;
        this.zoomed_Y_Axis = null;
      }
      let minMax = await this.getMinMaxRange();
      let difference = this.currentTimeRange * 3600000 || 3600000;
      if (this.logType == "time") {
        if (this.currentTimeRange > 2) this.currentTimeRange = 2;
        difference = this.currentTimeRange * 3600000 || 3600000;
        this.scaleMin =
          minMax.max - difference > minMax.min
            ? minMax.max - difference
            : minMax.min;
        this.scaleMax =
          minMax.max - difference > minMax.min
            ? minMax.max
            : minMax.min + difference;
        this.scaleMinDate = new Date(this.scaleMin).toLocaleString("en-US", {
          timeZone: this.time_zone,
        });
        this.scaleMaxDate = new Date(this.scaleMax);
        this.scaleMinFixed = minMax.min;
        this.scaleMaxFixed = minMax.max;
      } else {
        difference = this.currentFeetRange || 500;
        let minMax = await this.getMinMaxRange();
        if (minMax.max == minMax.min || minMax.max - difference < minMax.min)
          minMax.max = minMax.min + (difference + 500);
        this.scaleMin = minMax.max - difference;

        this.scaleMax = minMax.max;
        this.scaleMinFixed = minMax.min;
        this.scaleMaxFixed = minMax.max;
      }

      this.scaleMax = this.scaleMaxFixed;

      console.log(" minMax ", minMax);
      if (this.logType == "time") {
        this.completeData = [];
        this.data = [];
        this.mnemonic_groups = {};

        // difference = this.currentTimeRange
        // this.currentTimeRange = difference;
        // this.selectTimeRange(difference);
      } else {
        // this.currentFeetRange = difference;
        // this.selectFeet(difference);
      }
      console.log(
        " make it live : before : ",
        this.scaleMax,
        this.scaleMin,
        difference,
        this.data
      );
      await this.zoomIt(this.scaleMax, this.scaleMin, difference, false, "6");
      console.log(" make it live : after : ", this.data);
      this.getRealtimeData();
    },
    selectTimeRange(hrs) {
      if (this.isReplay) {
        this.currentTimeRange = hrs;
        this.changeHouesReplay(hrs);
        this.data = [];
        this.completeData = {};
        this.rangeInput = 0;
      } else {
        if (hrs < 1) hrs = Number(hrs).toFixed(2);
        this.currentTimeRange = hrs;
        if (this.liveDisabled) {
          this.takeOffSocket();
          if (hrs <= 2) {
            this.liveDisabled = false;
            if (hrs <= 2)
              setTimeout(() => {
                this.liveDisabled = true;
                this.getRealtimeData();
              }, 400);
          } else this.liveDisabled = false;
        }

        // this.socket = io(URL.HOST_URL);

        let scaleMin = 0;
        if (hrs == "default") {
          this.hrs = 1;
          scaleMin = this.scaleMax - 1 * 1000 * 60 * 60;
        } else {
          this.hrs = hrs;
          scaleMin = this.scaleMax - hrs * 1000 * 60 * 60;
        }

        this.scaleMin = scaleMin;
        this.zoomIt(
          this.scaleMax,
          this.scaleMin,
          this.currentTimeRange,
          false,
          "6"
        );
      }
    },
    showProps1(j, i) {
      let cProps = {};
      cProps["show"] = true;
      cProps["curve"] = this.trackConfig.tracks[j][i];
      this.curveProps[j + "-" + i] = cProps;
    },
    closeIt(j, i) {
      this.curveProps[j + "-" + i]["show"] = false;
    },
    setCurveProps(j, i, curve) {
      // console.log("@@@@@@@@@@@", j, i, curve, this.trackConfig.tracks[j][i]);
      this.trackConfig.tracks[j][i]["editedDescription"] =
        curve.editedDescription;
      if (this.trackConfig.tracks[j][i].name == "alert") {
        this.alertArr = curve.alertsArr;
      }
      this.curveProps[j + "-" + i]["show"] = false;
      let stroke = curve.thickness;
      let scaleMin = curve.scaleMin;
      let scaleMax = curve.scaleMax;
      let alertMin = curve.alertMin;
      let alertMax = curve.alertMax;
      let decimals = curve.decimals;
      let editedName = curve.editedDescription;
      let scaleType = curve.scaleType;
      if (stroke != null) {
        stroke = Number(stroke);
        this.trackConfig.tracks[j][i].stroke = stroke;
      }
      if (
        scaleMin != null &&
        scaleMin !== "" &&
        scaleMax != null &&
        scaleMax != "" &&
        scaleType !== null &&
        scaleType !== ""
      ) {
        scaleMin = Number(scaleMin);
        scaleMax = Number(scaleMax);
        this.trackConfig.tracks[j][i].scale = [scaleMin, scaleMax];
        // this.trackConfig.tracks[j][i].scaleType = "manual";
        this.trackConfig.tracks[j][i].scaleType = scaleType;
      }
      if (alertMin != null && alertMax != null) {
        alertMin = Number(alertMin);
        alertMax = Number(alertMax);

        this.trackConfig.tracks[j][i].alert = [alertMin, alertMax];
      }
      if (!isNaN(decimals)) {
        this.trackConfig.tracks[j][i].decimals = decimals;
      }
      if (editedName !== this.trackConfig.tracks[j][i].description) {
        this.trackConfig.tracks[j][i].editedName = editedName;
      } else if (
        editedName == this.trackConfig.tracks[j][i].description &&
        this.trackConfig.tracks[j][i]["editedName"]
      ) {
        delete this.trackConfig.tracks[j][i].editedName;
      }
      this.trackConfig.tracks[j][i].color = curve.color;
      this.trackConfig.tracks[j][i].isAlert = curve.isAlert;
      this.rerender("setCurveProps");
    },
    deleteCurve(j, i) {
      this.curveProps[j + "-" + i]["show"] = false;
      let canvas = document.getElementById(
        this.trackConfig.tracks[j][i].trackId
      );
      let tempTrackId = "";
      if (canvas) {
        tempTrackId = this.trackConfig.tracks[j][i].trackId;
      }
      this.trackConfig.tracks[j].splice(i, 1);
      console.log("delete config updates : ", this.trackConfig.tracks);
      if (this.trackConfig.tracks[j].length == 0) {
        console.log(" this.trackConfig.tracks[j] ", canvas);
        if (canvas) canvas.remove();
        this.trackConfig.tracks.splice(j, 1);
      } else {
        if (tempTrackId) this.trackConfig.tracks[j][0].trackId = tempTrackId;
      }
      this.configure();
      // setTimeout(() => {
      this.verticalplots(this.div_id, this.plots, true);
      // }, timeout);
      this.rerender();
      // visible = !visible;
    },
    startDrag(ev, props) {
      ev.dataTransfer.dropEffect = "move";
      ev.dataTransfer.effectAllowed = "move";
      let propString = JSON.stringify(props);
      ev.dataTransfer.setData("elProps", propString);
    },
    async onDrop(event) {
      let title;
      let min;
      let max;
      let color;
      let fullName;
      let singleTagData = null;
      let target = event.target.id;
      if (this.liveDisabled) {
        this.takeOffSocket();
        if (hrs <= 2) {
          this.liveDisabled = false;
          if (hrs <= 2)
            setTimeout(() => {
              this.liveDisabled = true;
              this.getRealtimeData();
            }, 700);
        } else this.liveDisabled = false;
      }
      console.log(
        "🚀 ➽ file: DisTrend.vue:2209  ➽ onDrop  ➽ target ⏩",
        target
      );
      let elProps = JSON.parse(event.dataTransfer.getData("elProps"));

      for (let j = 0; j < this.tracks.length; j++) {
        let trackId = this.tracks[j][0].trackId;
        if (target === "rect" + trackId) {
          if (this.tracks[j].length == 3) {
            this.$toast.error("Max limit is over", {
              position: "top",
              duration: "1000",
            });
            return false;
          }
        }
      }

      if (elProps.name == "child") {
        title = elProps.title.name;
        min = elProps.title.min;
        max = elProps.title.max;
        color = elProps.title.color;
        if (elProps.origin === this.compId) {
          this.deleteChildCurve(elProps.trackNbr, elProps.curveNbr);
        }
      } else {
        elProps.title = elProps.title
          ? elProps.title.toLowerCase()
          : elProps.fullName.toLowerCase();
        title = elProps.title;
        singleTagData = await this.getSingleTagData(
          elProps.title,
          false,
          "onDrop"
        );
        if (this.logType == "depth") {
          min = Number.POSITIVE_INFINITY;
          max = Number.NEGATIVE_INFINITY;
          let tmp;
          for (let i = singleTagData.length - 1; i >= 0; i--) {
            tmp = parseFloat(singleTagData[i][title.toLowerCase()]);
            if (tmp < min) min = tmp;
            if (tmp > max) max = tmp;
          }
        } else {
          // min = this.minmax[elProps.title].min
          // max = this.minmax[elProps.title].max
          min = d3.min(this.data[elProps.title]);
          max = d3.max(this.data[elProps.title]);
        }
      }
      fullName = elProps.fullName;
      this.emptyPlot = false;

      if (this.div_id == target) {
        let trackId = this.addTrack();
        let j = this.tracks.length - 1;
        await this.addNewCurve({
          title,
          min,
          max,
          trackId,
          j,
          color,
          fullName,
        });
      } else {
        for (let j = 0; j < this.tracks.length; j++) {
          let trackId = this.tracks[j][0].trackId;

          if (target === "rect" + trackId) {
            await this.addNewCurve({
              title,
              min,
              max,
              trackId,
              j,
              color,
              fullName,
            });
            break;
          }
        }
      }

      this.$store.dispatch("rect/setConfig", {
        disId: this.displayId,
        widId: this.id,
        config: this.trackConfig,
      });
      // this.selectedMnenonics.unshift(title);

      this.setTrackWidth(this.trackConfig.tracks.length);
      let noDIV = d3
        .select("#" + this.div_id)
        .selectAll("div")
        .remove();
      let noSVG = d3
        .select("#" + this.div_id)
        .selectAll("svg")
        .remove();
      this.rerender("onDrop");
      // this.rerenderTest(this.compId);
      if (this.isReplay) {
        this.liveDisabled = false;
      } else {
        if (this.liveDisabled && this.status == "UPDATING") {
          this.getRealtimeData();
        }
      }
    },
    deleteChildCurve(trackNbr, curveNbr) {
      this.trackConfig.tracks[trackNbr].splice(curveNbr, 1);
      if (this.trackConfig.tracks[trackNbr].length == 0) {
        this.trackConfig.tracks.splice(trackNbr, 1);
        this.totTracks.splice(trackNbr, 1);
      }
    },
    setTrackWidth(nbr) {
      let usableWidth =
        // this.templateWidth - this.sliderWidth - this.leftbarWidth - 50;
        this.templateWidth - this.sliderWidth - this.leftbarWidth - nbr * 5;
      this.singleTrackWidth = Math.floor(usableWidth / nbr);
      if (this.singleTrackWidth > this.trackWidth) {
        this.singleTrackWidth = this.trackWidth;
      }
    },
    setTrackHeight() {
      this.singleTrackHeight = this.templateHeight - this.bheight - 39;
    },
    addNewCurve(title) {
      let currentCurve = {};
      currentCurve.name = title.title;
      currentCurve.fullName = title.fullName || "";
      if (title.color != null) {
        currentCurve.color = title.color;
      } else {
        currentCurve.color = this.getRandomColor();
      }
      if (title.j != null) {
        currentCurve.trackNbr = title.j;
        if (this.tracks.length > 0) {
          currentCurve.curveNbr = this.tracks[title.j].length;
        }
      } else {
        currentCurve.trackNbr = 0;
        currentCurve.curveNbr = 0;
      }
      currentCurve.trackId = title.trackId;
      currentCurve.min = title.min;
      currentCurve.max = title.max;
      currentCurve.scale = [title.min, title.max];
      currentCurve.scaleType = "auto";
      this.trackConfig.tracks[currentCurve.trackNbr].push(currentCurve);
    },
    getRandomColor() {
      let letters = "0123456789ABCDEF";
      let color = "#";
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    },
    createLeftBar(div_id, templates) {
      try {
        if (this.logType == "time") {
          // let depth_min_sec = this.minmax['dmea'].min

          // let depth_max_sec = this.minmax['dmea'].max
          this.depthMaxValue = 0;
          this.depthMinValue = 0;
        } else {
          let depth_min_sec = d3.min(this.data["time"]) || null;
          let depth_max_sec = d3.max(this.data["time"]) || null;
          this.depthMaxValue = parseInt(depth_max_sec);
          this.depthMinValue = parseInt(depth_min_sec);
        }
        if (isNaN(this.depthMaxValue) && isNaN(this.depthMinValue)) {
          this.leftbarWidth = 70;
        } else {
          this.leftbarWidth = 120;
        }
        let template_for_plotting = templates[0];
        let template_overall = template_for_plotting[0]["curve_box"];

        let yAxisHolder = d3.select("#" + this.id + "leftbarcontainer");
        const trendtimeStampCol = this.darkDark !== "white" ? "dv" : "ddv";
        if (!document.getElementById(this.id + "leftbarcontainer")) {
          yAxisHolder = d3.select("#" + div_id).append("div");

          yAxisHolder
            .attr("id", this.id + "leftbarcontainer")
            .attr("class", "light_trend_scale")
            .style("display", "flex")
            .style("vertical-align", "top")
            .style("margin-right", "0px")
            .style("width", this.leftbarWidth + "px");
        }

        this.leftRangeSliderSet = true;
        let height_components = template_overall["height"];
        if (this.logType == "depth") {
          this.yLeftScale = d3
            .scaleLinear()
            .domain([this.scaleMax, this.scaleMin])
            .range([height_components - 20, 0]);
          // .nice();
          if (!isNaN(this.depthMaxValue) && !isNaN(this.depthMinValue))
            this.yLeftScaleCompare = d3
              .scaleTime()
              .domain([this.depthMaxValue, this.depthMinValue])
              .range([height_components - 20, 0]);
          // .nice();
        } else {
          this.yLeftScale = d3
            .scaleTime()
            .domain([this.scaleMax, this.scaleMin])
            .range([height_components - 20, 0]);
          // .nice();
        }
        let y = this.yLeftScale;
        if (this.isZoom && this.zoomed_Y_Axis)
          y = this.zoomed_Y_Axis.range([height_components - 20, 0]);

        d3.select("#" + this.id + "leftbar-outer").remove();
        let leftbarOuter = yAxisHolder
          .append("div")
          .attr("id", this.id + "leftbar-outer")
          .style("width", this.leftbarWidth + "px");

        let leftbarInner = leftbarOuter
          .append("div")
          .style("max-height", height_components + "px")
          .style("scrollbar-width", "thin")
          .style("width", this.leftbarWidth + "px")
          .style("height", height_components + "px")
          .style("direction", "rtl")
          .style("scrollbar-track", "red");

        let svg = leftbarInner
          .append("svg")
          .style("width", this.leftbarWidth + "px")
          .style("overflow", "visible")
          .attr("height", height_components);
        let yTicks = null;
        let yTicksCompare = null;
        if (this.logType == "time") {
          let tickInterval = parseInt((this.scaleMax - this.scaleMin) / 9);
          let tempScaleMax = this.scaleMax;
          let tempScaleMin = this.scaleMin;
          if (this.isZoom && this.zoomed_Y_Axis) {
            y = this.zoomed_Y_Axis.range([height_components - 20, 0]);
            let zoomed_min_max = this.zoomed_Y_Axis.domain();
            tempScaleMax = moment(zoomed_min_max[0]).unix() * 1000;
            tempScaleMin = moment(zoomed_min_max[1]).unix() * 1000;
            tickInterval = parseInt((tempScaleMax - tempScaleMin) / 9);
          }
          this.timeTicks = [];
          for (
            let tick = tempScaleMin;
            tick < tempScaleMax;
            tick = tick + tickInterval
          ) {
            if (this.timeTicks.length <= 8) this.timeTicks.push(tick);
          }
          this.timeTicks.push(tempScaleMax);

          yTicks = d3
            .axisLeft()
            .scale(y)
            .tickValues(this.timeTicks)
            .tickSizeInner(120);
        } else {
          let tickInterval = (this.scaleMax - this.scaleMin) / 9;
          let tempScaleMax = this.scaleMax;
          let tempScaleMin = this.scaleMin;
          if (this.isZoom && this.zoomed_Y_Axis) {
            y = this.zoomed_Y_Axis.range([height_components - 20, 0]);
            let zoomed_min_max = this.zoomed_Y_Axis.domain();
            tempScaleMax = zoomed_min_max[0];
            tempScaleMin = zoomed_min_max[1];
            tickInterval = (zoomed_min_max[0] - zoomed_min_max[1]) / 9;
          }

          this.depthTicks = [];
          for (
            let tick = tempScaleMin;
            tick <= tempScaleMax;
            tick = tick + tickInterval
          ) {
            if (this.depthTicks.length <= 8) this.depthTicks.push(tick);
          }

          this.depthTicks.push(tempScaleMax);

          let tickIntervalCompare = parseInt(
            (this.depthMaxValue - this.depthMinValue) / 9
          );

          this.timeTicks = [];
          if (!isNaN(this.depthMaxValue) && !isNaN(this.depthMinValue)) {
            for (
              let tick = this.depthMinValue;
              tick < this.depthMaxValue;
              tick = tick + tickIntervalCompare
            ) {
              this.timeTicks.push(tick);
            }
            this.timeTicks.push(this.depthMaxValue);

            if (this.data["dmea"] && this.data["dmea"].length > 0) {
              yTicks = d3
                .axisLeft()
                .scale(this.yLeftScaleCompare)
                .tickValues(this.timeTicks)
                .tickSize(-65);
              // yTicks.tickFormat(d3.timeFormat("%Y-%m-%d %H:%M:%S"));
            }
          }
          yTicksCompare = d3
            .axisLeft()
            .scale(y)
            .tickValues(this.depthTicks)
            .tickSizeInner(
              isNaN(this.depthMaxValue) && isNaN(this.depthMinValue) ? 70 : 120
            );
        }

        if (this.logType == "time") {
          let timeAxis = svg
            .append("g")
            .attr("transform", "translate(35,0)")
            .call(yTicks);

          timeAxis
            .selectAll("line")
            .style("opacity", "0.2")
            .attr("transform", "translate(85,0)");
          timeAxis
            .selectAll("path")
            .style("opacity", "0")
            .attr("transform", "translate(85,0)");
          // timeAxis.selectAll("path").style("opacity", "1").attr("transform", "translate(10,0)");
          let bisectDate = d3.bisector(function (d) {
            return d;
          }).left;
          let scale = y;
          let data = this.data;
          timeAxis.selectAll("text").call(function (t) {
            t.each(function (d) {
              // for each one
              let self = d3.select(this);

              let s = [];
              s[1] = moment(new Date(d).toLocaleString("en-US")).format(
                "MM-DD-YYYY"
              );
              s[0] = moment(new Date(d).toLocaleString("en-US")).format(
                "HH:mm:ss"
              );
              // self.path("")
              self.text(""); // clear it out

              // self
              //   .append("tspan")
              //   .attr("x", 40)
              //   .attr("dy", "-0.5em")
              //   .attr("class", trendtimeStampCol)
              //   .text(s[1])
              //   .text("")
              //   .style("letter-spacing", "2.5px")
              //   .style("font-size", "min(1vw, 9px)");

              self
                .append("tspan") // insert two tspans
                .attr("x", 0)
                .attr("dy", "-0.4em")
                .text(s[1])
                .style("font-size", "calc(5px + 0.3vw)");

              self
                .append("tspan") // insert two tspans
                .attr("x", 30)
                .attr("dy", "1.5em")
                .text(s[0])
                .style("font-size", "calc(5px + 0.3vw)");

              if (data["dmea"] && data["dmea"].length) {
                let y0 = scale.invert(d),
                  i = bisectDate(data["time"], d, 1),
                  d0 = data["dmea"][i - 1];
                // d1 = data[i];
                self
                  .append("tspan") // insert two tspans
                  .attr("x", "-2.5em")
                  .attr("dy", "0em")
                  .text(d0 ? "" + Number(d0).toFixed(2) : 0)
                  .style("letter-spacing", "0px")
                  .style("font-size", "calc(5px + 0.3vw)");
                // .style("font-size", "8px");
              }
            });
          });
        } else {
          if (!isNaN(this.depthMaxValue) && !isNaN(this.depthMinValue))
            if (this.data["dmea"] && this.data["dmea"].length > 0) {
              let timeAxis = svg
                .append("g")
                .attr("transform", "translate(35,0)")
                .call(yTicks);

              timeAxis
                .selectAll("line")
                .style("opacity", "0")
                .attr("transform", "translate(10,0)");
              timeAxis
                .selectAll("path")
                .style("opacity", "0")
                .attr("transform", "translate(10,0)");
              // timeAxis.selectAll("path").style("opacity", "0");
              timeAxis.selectAll("text").call(function (t) {
                t.each(function (d) {
                  // for each one
                  let self = d3.select(this);
                  let s = [];
                  s[1] = moment(new Date(d).toLocaleString("en-US")).format(
                    "MM-DD-YYYY"
                  );
                  s[0] = moment(new Date(d).toLocaleString("en-US")).format(
                    "HH:mm:ss"
                  );
                  // self.path("")
                  self.text(""); // clear it out

                  self
                    .append("tspan")
                    .attr("x", "-0.5em")
                    .attr("dy", "-0.5em")
                    .attr("class", trendtimeStampCol)
                    .style("font-size", "calc(5px + 0.3vw)")
                    // .text(s[1]);

                    .text(s[1]);
                  self
                    .append("tspan") // insert two tspans
                    .attr("x", "-2em")
                    .attr("dy", "1.5em")
                    .style("font-size", "calc(5px + 0.3vw)")
                    .text(s[0]);
                });
              });
            }

          let depthAxis = svg
            .append("g")
            .attr("transform", "translate(35,0)")
            .call(yTicksCompare);
          isNaN(this.depthMaxValue) && isNaN(this.depthMinValue)
            ? depthAxis
                .selectAll("line")
                .style("opacity", "0.2")
                .attr("transform", "translate(36,0)")
            : depthAxis
                .selectAll("line")
                .style("opacity", "0.2")
                .attr("transform", "translate(85,0)");

          depthAxis
            .selectAll("path")
            .style("opacity", "0")
            .attr("transform", "translate(10,0)");

          depthAxis
            .selectAll("text")
            .attr(
              "x",
              isNaN(this.depthMaxValue) && isNaN(this.depthMinValue)
                ? -20
                : "40"
            )
            .attr("dy", "1em")
            .style("font-size", "calc(5px + 0.3vw)");
        }
      } catch (error) {
        console.error(
          "🚀 ➽ file: DisTrend.vue:2523  ➽ createLeftBar  ➽ error ⏩",
          error
        );
      }
    },
    createActcBar(div_id, templates) {
      try {
        if (this.logType == "time") {
          this.depthMaxValue = 0;
          this.depthMinValue = 0;
        } else {
          let depth_min_sec = d3.min(this.data["time"]) || null;
          let depth_max_sec = d3.max(this.data["time"]) || null;
          this.depthMaxValue = parseInt(depth_max_sec);
          this.depthMinValue = parseInt(depth_min_sec);
        }
        if (isNaN(this.depthMaxValue) && isNaN(this.depthMinValue)) {
          this.leftbarWidth = 80;
        } else {
          this.leftbarWidth = 130;
        }
        let template_for_plotting = templates[0];
        let template_overall = template_for_plotting[0]["curve_box"];

        d3.select("#" + this.id + "leftbarcontainer").style(
          "width",
          this.leftbarWidth + "px"
        );

        let height_components = template_overall["height"];
        let y = this.yLeftScale;
        if (this.isZoom && this.zoomed_Y_Axis) y = this.zoomed_Y_Axis;
        let width = 10;
        let graph_type = this.logType == "time" ? this.logType : "dmea";

        d3.select("#" + this.id + "left_actc_bar").remove();

        let canvas = document.getElementById(this.id + "left_actc_bar");

        if (canvas) {
          canvas.width = width;
          canvas.height = height_components;
        } else {
          d3.select("#" + this.id + "leftbarcontainer")
            .append("canvas")
            .attr("id", this.id + "left_actc_bar")
            .attr("class", "track_styles")
            // .attr(
            //   "style",
            //   `border: 1px solid ${this.darkDark ? "white" : "#c1c0c0"
            //   }`
            // )
            .style("background-color", "var(--colorBoxBg)")
            .attr("height", height_components + "px")
            .attr("width", width + "px");
          canvas = document.getElementById(this.id + "left_actc_bar");
        }

        let ctx = canvas.getContext("2d");
        ctx.clearRect(0, 0, width, height_components);
        let startActcCode = 0;
        let endActcCode = 0;
        let startActcTime = 0;
        let endActcTime = 0;
        let totalRecords = this.data["activitycode"].length - 1;
        let actcColor = "white";
        let logType = this.logType;
        ctx.beginPath();
        for (let i = 1; i < this.data["activitycode"].length; i++) {
          let d = this.data["activitycode"][i];
          if (i == 0) {
            startActcCode = d;
            startActcTime = y(this.data[graph_type][i]);
          } else {
            endActcCode = d;
            endActcTime = y(this.data[graph_type][i]);

            if (
              parseInt(startActcCode) != parseInt(endActcCode) ||
              i == totalRecords
            ) {
              let tooltip = "";
              switch (startActcCode) {
                case 1:
                  actcColor = "#92D050";
                  tooltip = "ROT DRLG";
                  break;
                case 2:
                  actcColor = "#00B050";
                  tooltip = "SLD DRLG";
                  break;
                case 3:
                  actcColor = "#FFC000";
                  tooltip = "CIRC WITH ROT";
                  break;
                case 4:
                  actcColor = "#FFFFFF";
                  tooltip = "UNKNOWN";
                  break;
                case 10:
                  actcColor = "#833C0C";
                  tooltip = "CIRCULATION";
                  break;
                case 11:
                  actcColor = "#00CC99";
                  tooltip = "REAMING";
                  break;
                case 12:
                  actcColor = "#008000";
                  tooltip = "BACKREAM";
                  break;
                case 13:
                  actcColor = "#CC3300";
                  tooltip = "CONNECTION";
                  break;
                case 14:
                  actcColor = "#2F75B5"; // Bright Blue
                  tooltip = "TIH-DRLG";
                  break;
                case 15:
                  actcColor = "#8EA9DB"; //Cadet Blue
                  tooltip = "POOH-DRLG";
                  break;
                case 16:
                  actcColor = "#DBDBDB";
                  tooltip = "CIRC WITH ROT";
                  break;
                case 17:
                  actcColor = "#FFFF00";
                  tooltip = "CIRCULATION";
                  break;
                case 30:
                  actcColor = "#FFE699";
                  tooltip = "STATIONARY";
                  break;
                case 5:
                  actcColor = "#1F4E78";
                  tooltip = "TIH";
                  break;
                case 6:
                  actcColor = "#BDD7EE";
                  tooltip = "POOH";
                  break;
                case 20:
                  actcColor = "#8497B0";
                  tooltip = "PUMPOUT";
                  break;
                case 21:
                  actcColor = "#9900FF";
                  tooltip = "REAM-TRIP";
                  break;
                case 22:
                  actcColor = "#FF3399";
                  tooltip = "BACK REAM-TRIP";
                  break;
                case 23:
                  actcColor = "#CCCC00";
                  tooltip = "CONN. TRIP";
                  break;
                case 24:
                  actcColor = "#CC9900";
                  tooltip = "CIRC W/O ROT-TRIP";
                  break;
                case 25:
                  actcColor = "#FF9933";
                  tooltip = "CIRC W/O ROT-TRIP";
                  break;
                case 26:
                  actcColor = "#833C0C";
                  tooltip = "CIRCULATION";
                  break;
                case 27:
                  actcColor = "#945E0D";

                  tooltip = "WASHDOWN";
                  break;

                default:
                  break;
              }

              ctx.lineWidth = 20;
              ctx.beginPath();
              ctx.moveTo(0, startActcTime);
              ctx.lineTo(0, endActcTime);
              ctx.strokeStyle = actcColor;
              ctx.stroke();
              if (logType == "time") {
                startActcCode = d;
                startActcTime = y(this.data[graph_type][i]);
              } else {
                startActcCode = d;
                startActcTime = y(this.data[graph_type][i]);
              }
            } else {
            }
          }
        }
      } catch (error) {
        console.log(
          "🚀 ➽ file: DisTrend.vue:2523  ➽ createActcBar  ➽ error ⏩",
          error
        );
      }
    },
    verticalplots(
      div_id,
      templates,
      show_all = true,
      remove_preexisting = true
    ) {
      // let noDIV = d3
      //   .select("#" + div_id)
      //   .selectAll("div")
      //   .remove();
      // let noSVG = d3
      //   .select("#" + div_id)
      //   .selectAll("svg")
      //   .remove();

      if (templates.length > 0) {
        this.createLeftBar(div_id, templates);
        if (!this.isDis) this.createActcBar(div_id, templates);
      }
      for (let i = 0; i < templates.length; i++) {
        //  templates.forEach((it, i)=>{
        let arr = [];
        let track_bg_s = this.darkDark !== "white" ? "" : this.bgColor;

        if (!document.getElementById(templates[i][0]["curve_box"]["trackId"])) {
          // console.log(
          //   "🚀 ➽ file: DisTrend.vue:2675",
          //   templates[i][0]["curve_box"]["trackId"],
          //   document.getElementById(templates[i][0]["curve_box"]["trackId"])
          // );
          let curvebox_holder = d3.select("#" + div_id).append("div");
          curvebox_holder
            .style("vertical-align", "middle")
            .style("background", this.track_bg_s)
            .style("transition:", "var(--transition)")
            .attr("id", templates[i][0]["curve_box"]["trackId"]);
          // console.log("_______ 1 height _________ ");
          //// to control view of plots on site, user can show-hide by triggering action here. However, if show_all = false then none will show, so developer will need to change CSS with another function one by one!
          if (show_all) {
            curvebox_holder.style("display", "inline-block");
            curvebox_holder.style("margin-right", "0px");
          } else {
            curvebox_holder.style("display", "none");
          }
        }

        // templates[i][0]["curve_box"]["div_id"] = div_id + "curvebox_holder" + i;

        templates[i][0]["curve_box"]["div_id"] =
          templates[i][0]["curve_box"]["trackId"];

        // new_templates.push(templates[i]);

        this.curveBox(templates[i], i, div_id, this.data);
      }
    },
    annotationClick(e, isAnnotation, annotation_type) {
      this.hoverEvent = e;
      // let offsetYPercent =
      //   (e.offsetY / e.target.getBoundingClientRect().height) * 100;
      // let parentHeight = e.target.getBoundingClientRect().height;
      let timeRangeInSeconds =
        this.logType == "time"
          ? this.scaleMax - this.scaleMin
          : this.scaleMax - this.scaleMin;
      // let tooltipShift = this.logType == "time" ? 100 : timeRangeInSeconds / 2;

      let data = this.data;
      let bisectDate = d3.bisector(function (d) {
        return d;
      }).left; // **

      let y = this.yLeftScale;
      if (this.isZoom && this.zoomed_Y_Axis) y = this.zoomed_Y_Axis;
      let y0 = 0;
      let index = 0;
      let log = "time";
      if (this.logType == "depth") {
        y0 = y.invert(d3.pointer(e)[1]);
        log = "dmea";
      } else {
        y0 = moment(y.invert(d3.pointer(e)[1])).unix() * 1000;
      }
      index = bisectDate(data[log], y0, 1);
      let mouse_y,
        mouse_x = -1;
      if (isAnnotation) {
        if(annotation_type == 'ALERTS'){
          for (let i of this.alertTimes) {
            if (e.y - 300 >= i.y && e.y - 300 <= i.yEnd) {
              mouse_y = e.y;
              mouse_x = e.x - 50;
              this.clickedAlert = i.alert;
              break;
            } else {
            }
          }
        }
        else if(annotation_type == 'STICK SLIP'){
          for (let i of this.alertStickslip) {
            if (e.y - 300 >= i.y && e.y - 300 <= i.yEnd) {
              mouse_y = e.y;
              mouse_x = e.x - 50;
              this.clickedAlert = i.alert;
              break;
            } else {
            }
          }
        }
        else if(annotation_type == 'WASH OUT'){
          for (let i of this.alertWashout) {
            if (e.y - 300 >= i.y && e.y - 300 <= i.yEnd) {
              mouse_y = e.y;
              mouse_x = e.x - 50;
              this.clickedAlert = i.alert;
              break;
            } else {
            }
          }
        }
      }
    },
    mousemove(e, isAnnotation, isStickSlip = false, isWashout = false) {
      try {
        let index = 0;
        let parentHeight = 0;
        let parentTop = 0;
        let log = "time";
        if (this.logType == "depth") {
          log = "dmea";
        }
        if (e && e.y - this.tooltip_upper_scale >= this.yLeftScale.range()[0]) {
          this.mouse_y_tooltip = -1;
          return false;
        }
        let timeRangeInSeconds = 0;
        let bisectDate = d3.bisector(function (d) {
          return d;
        }).left; // **
        if (e) {
          this.tooltip_horizontalline_ypos = e.y;
          parentHeight = e.target.getBoundingClientRect().height;
          parentTop = e.target.getBoundingClientRect().top;
          timeRangeInSeconds =
            this.logType == "time"
              ? this.scaleMax - this.scaleMin
              : this.scaleMax - this.scaleMin;

          let y = this.yLeftScale;
          if (this.isZoom && this.zoomed_Y_Axis) y = this.zoomed_Y_Axis;
          let y0 = 0;
          this.tooltipUpdateIndex = d3.pointer(e)[1];
          if (this.logType == "depth") {
            y0 = y.invert(this.tooltipUpdateIndex);
          } else {
            y0 = moment(y.invert(this.tooltipUpdateIndex)).unix() * 1000;
          }

          index = bisectDate(this.data[log], y0, 1) - 1;

          // if (this.isShowTooltip)
          //   this.mouse_y_tooltip =
          //     e.y > parentHeight
          //       ? parentHeight - 65 + (e.y - parentHeight) - 165
          //       : e.y - 65;
          if (this.isShowTooltip) {
            if (parentHeight < 200) this.mouse_y_tooltip = e.y - parentTop;
            else {
              if (window.screen.width <= 1280) {
                this.mouse_y_tooltip = e.y - parentTop * 0.8;
              } else {
                this.mouse_y_tooltip =
                  e.y - parentTop < parentHeight / 2
                    ? e.y - parentTop + parentHeight / 2
                    : e.y - parentTop;
              }
            }

            this.mouse_y_tooltip_line = e.y - parentTop + 138 + 28;

            // console.log(" mouse move event : ", e)
          }
          // if (this.mouse_y_tooltip < 200) {
          //   this.mouse_y_tooltip += 200;
          // }
        } else {
          let y = this.yLeftScale;
          let y0 = 0;
          if (this.logType == "depth") {
            y0 = y.invert(this.tooltipUpdateIndex);
          } else {
            y0 = moment(y.invert(this.tooltipUpdateIndex)).unix() * 1000;
          }

          index = bisectDate(this.data[log], y0, 1) - 1;
        }

        let d = {};
        let all_curves = [];
        if(this.trackConfig.tracks && this.trackConfig.tracks.length){
          all_curves = this.trackConfig.tracks.flat();
        }
        for (let i in this.data) {
          let decimals_to_fix = 2;
          if(all_curves && all_curves.length){
            let found = all_curves.find(e=> e.name == i);
            if(found) decimals_to_fix = found?.decimals || found?.decimals == 0 ? parseInt(found?.decimals) : 2;
          }
          if (i == "activitycode") {
            let text = {
              1: "ROT DRLG",
              2: "SLD DRLG",
              3: "CIRC WITH ROT",
              4: "UNKNOWN",
              10: "CIRCULATION",
              11: "REAMING",
              12: "BACKREAM",
              13: "CONNECTION",
              14: "TIH-DRLG",
              15: "POOH-DRLG",
              16: "CIRC WITH ROT",
              17: "CIRCULATION",
              30: "STATIONARY",
              5: "TIH",
              6: "POOH",
              20: "PUMPOUT",
              21: "REAM-TRIP",
              22: "BACK REAM-TRIP",
              23: "CONN.TRIP",
              24: "CIRC WITH ROT-TRIP",
              25: "CIRC W/O ROT-TRIP",
              26: "CIRCULATION",
              27: "WASHDOWN",
            };
            this.tooltip_d[i] = text[this.data[i][index]];
          } else if (i == "stickslipindex" || i == "washoutindex") {
            this.tooltip_d[i] =
              isNaN(parseFloat(this.data[i][index])) ||
              i == "time" ||
              this.data[i][index] == 0
                ? this.data[i][index]
                : this.data[i][index] == "null" || this.data[i][index] == null
                ? ""
                : parseFloat(this.data[i][index]).toFixed(4);
          } else
            this.tooltip_d[i] =
              isNaN(parseFloat(this.data[i][index])) ||
              i == "time" ||
              this.data[i][index] == 0
                ? this.data[i][index]
                : this.data[i][index] == "null" || this.data[i][index] == null
                ? ""
                : parseFloat(this.data[i][index]).toFixed(decimals_to_fix);
        }

        if (this.isShowAlerts && isAnnotation) {
          clearTimeout(this.hoveringAlertTimmer);
          this.hoveringAlertTimmer = setTimeout(() => {
            this.mouse_y = -1;
            this.mouse_x = -1;
            this.hoveringAlert = "";
          }, 0);

          let remaining_height = window.innerHeight - e.clientY;

          for (let i of this.alertTimes) {
            if (e.y - 300 >= i.y && e.y - 300 <= i.yEnd) {
              this.mouse_y =
                remaining_height < 388 ? window.innerHeight - 388 : e.clientY;
              this.mouse_x = e.x - 60;
              this.hoveringAlert = i.alert;
              clearTimeout(this.hoveringAlertTimmer);
              break;
            } else {
            }
          }
        } else if (this.isShowAlerts && isStickSlip) {
          clearTimeout(this.hoveringAlertTimmer);
          this.hoveringAlertTimmer = setTimeout(() => {
            this.mouse_y = -1;
            this.mouse_x = -1;
            this.hoveringAlert = "";
          }, 0);

          let remaining_height = window.innerHeight - e.clientY;

          this.mouse_y =
            remaining_height < 388 ? window.innerHeight - 388 : e.clientY;

          for (let i of this.alertStickslip) {
            if (e.y - 300 >= i.y && e.y - 300 <= i.yEnd) {
              this.mouse_y =
                remaining_height < 388 ? window.innerHeight - 388 : e.clientY;
              this.mouse_x = e.x - 60;
              this.hoveringAlert = i.alert;
              clearTimeout(this.hoveringAlertTimmer);
              break;
            } else {
            }
          }
        } else if (this.isShowAlerts && isWashout) {
          clearTimeout(this.hoveringAlertTimmer);
          this.hoveringAlertTimmer = setTimeout(() => {
            this.mouse_y = -1;
            this.mouse_x = -1;
            this.hoveringAlert = "";
          }, 0);

          let remaining_height = window.innerHeight - e.clientY;

          this.mouse_y =
            remaining_height < 388 ? window.innerHeight - 388 : e.clientY;

          for (let i of this.alertWashout) {
            if (e.y - 300 >= i.y && e.y - 300 <= i.yEnd) {
              this.mouse_y =
                remaining_height < 388 ? window.innerHeight - 388 : e.clientY;
              this.mouse_x = e.x - 60;
              this.hoveringAlert = i.alert;
              clearTimeout(this.hoveringAlertTimmer);
              break;
            } else {
            }
          }
        } else {
          this.mouse_y = -1;
          this.mouse_x = -1;
          this.hoveringAlert = "";
        }
      } catch (error) {
        console.error(
          "🚀 ➽ file: DisTrend.vue:3916  ➽ mousemove  ➽ error ⏩",
          error
        );
      }
    },
    mouseout(e) {
      // for (let focusAxis of this.focusAxisList) {
      //   let focus = focusAxis["focus"];
      //   focus
      //     .style("display", "none")
      //     .transition()
      //     .duration(250)
      //     .style("opacity", 0);
      // }
    },
    mousescroll(e) {
      if (this.logType == "time") {
        this.takeOffSocket();
        this.liveDisabled = false;
        let scrollAmount = parseInt((this.scaleMax - this.scaleMin) / 4);
        // downscroll code
        if (e.deltaY > 0) {
          if (this.scaleMax == this.scaleMaxFixed) {
            if (this.mousewheel_toast_timeout)
              clearTimeout(this.mousewheel_toast_timeout);
            this.mousewheel_toast_timeout = setTimeout(() => {
              this.$toast.info("Well end reached", {
                position: "top",
                duration: 1000,
              });
              clearTimeout(this.mousewheel_toast_timeout);
            }, 300);

            return true;
          }
          if (this.scaleMaxFixed - scrollAmount - 2 > this.scaleMax) {
            this.scaleMax = this.scaleMax + scrollAmount;
            this.scaleMin = this.scaleMin + scrollAmount;
          } else {
            this.scaleMax = this.scaleMaxFixed;
            this.scaleMin = this.scaleMax - this.currentTimeRange * 3600000;
          }
        }
        // upscroll code
        if (e.deltaY < 0) {
          if (this.scaleMin == this.scaleMinFixed) {
            if (this.mousewheel_toast_timeout)
              clearTimeout(this.mousewheel_toast_timeout);
            this.mousewheel_toast_timeout = setTimeout(() => {
              this.$toast.info("Well start reached", {
                position: "top",
                duration: 1000,
              });
              clearTimeout(this.mousewheel_toast_timeout);
            }, 300);
            return true;
          }
          if (this.scaleMinFixed < this.scaleMin - scrollAmount) {
            this.scaleMax = this.scaleMax - scrollAmount;
            this.scaleMin = this.scaleMin - scrollAmount;
          } else {
            this.scaleMin = this.scaleMinFixed;
            this.scaleMax =
              this.scaleMinFixed + this.currentTimeRange * 3600000;
          }
        }

        if (this.mousewheeltimeout) clearTimeout(this.mousewheeltimeout);
        this.mousewheeltimeout = setTimeout(() => {
          this.zoomIt(
            this.scaleMax,
            this.scaleMin,
            this.feet,
            false,
            "mousewheel"
          );
          clearTimeout(this.mousewheeltimeout);
        }, 300);
      } else {
        this.takeOffSocket();
        this.liveDisabled = false;

        // downscroll code
        if (e.deltaY > 0)
          if (this.scaleMaxFixed - this.currentFeetRange > this.scaleMax) {
            this.scaleMax = this.scaleMax + this.currentFeetRange;
            this.scaleMin = this.scaleMin + this.currentFeetRange;
          } else {
            this.scaleMax = this.scaleMaxFixed;
            this.scaleMin = this.scaleMax - this.currentFeetRange;
          }
        // upscroll code
        if (e.deltaY < 0)
          if (this.scaleMinFixed < this.scaleMin - this.currentFeetRange) {
            this.scaleMax = this.scaleMax - this.currentFeetRange;
            this.scaleMin = this.scaleMin - this.currentFeetRange;
          } else {
            this.scaleMin = this.scaleMinFixed;
            this.scaleMax = this.scaleMin + this.currentFeetRange;
          }

        this.zoomIt(
          this.scaleMax,
          this.scaleMin,
          this.feet,
          false,
          "mousewheel"
        );
      }
    },
    zoomed(e) {
      let transform = e.transform;
      // Update scales with zoom
      if (this.isZoom) {
        this.takeOffSocket();
        this.liveDisabled = false;
        const new_yScale = transform.rescaleY(this.yLeftScale);

        // console.log("y re scale range : ", new_yScale);
        this.zoomed_Y_Axis = new_yScale;
        // Redraw line with new scales
        // this.inItChat();
        // Update the scales
        // let minX = new_xScale.domain()[0];
        // let maxX = new_xScale.domain()[1];
        let maxY = new_yScale.domain()[0];
        let minY = new_yScale.domain()[1];

        if (this.logType == "depth") {
          // this.scaleMax = new_yScale.domain()[0];
          // this.scaleMin = new_yScale.domain()[1];
          // this.zoomed_Y_Axis = null;
          // this.zoomIt(this.scaleMax, this.scaleMin, (this.scaleMax - this.scaleMin), false, "zoomed");
          this.currentFeetRange = Number(maxY - minY).toFixed(2);
          this.createLeftBar(this.div_id, this.plots);
          this.rerender();
        } else {
          // this.zoomIt(this.scaleMax, this.scaleMin, this.feet, false, "zoomed");
          minY = moment(minY).unix() * 1000;
          maxY = moment(maxY).unix() * 1000;
          // this.currentTimeRange = Number(
          //   (maxY - minY) / (60 * 60 * 1000)
          // ).toFixed(2);
          this.createLeftBar(this.div_id, this.plots);
          this.rerender();
        }
      } else {
        console.log("source event : zoomed : ", e);
        if (e.sourceEvent.deltaY) this.mousescroll(e.sourceEvent);
      }
    },
    async curveBox(template_for_plotting, index, divId, data) {
      try {
        // console.time("curve box divId " + divId)
        let template_overall = template_for_plotting[0]["curve_box"];
        let template_components = template_for_plotting[0]["components"];
        let template_curves = template_components[0]["curves"][0];
        let height_components = template_overall["height"];
        let margin = template_overall["margin"];
        let width = template_overall["width"];

        if (data == null || data.length == 0 || !data["time"]) {
          this.isRerendering = false;
          return;
        }
        //// Variables related to curves, these should all be arrays with one or more values!
        let curve_names = template_curves["curve_names"];
        let curve_colors = template_curves["curve_colors"];
        let curveScales = template_curves["scale"];
        let curveScaleType = template_curves["scaleType"];

        let div_id = template_overall["div_id"];

        let canvas = document.getElementById("rect" + div_id);

        if (canvas) {
          canvas.width = width;
          canvas.height = height_components;

          // let  new_element = old_element.cloneNode(true);
          // canvas.parentNode.replaceChild(new_element, canvas);
          let isAnnotation = curve_names.indexOf("alert") > -1;
          let isStickSlip = curve_names.indexOf("stickslipindex") > -1;
          let isWashout = curve_names.indexOf("washoutindex") > -1;
          if (this.isShowTooltip && this.tooltipUpdateIndex != null)
            this.mousemove(null, isAnnotation, isStickSlip, isWashout);
        } else {
          d3.select("#" + div_id)
            .style("margin-left", "5px")
            .append("canvas")
            .attr("id", "rect" + div_id)
            .attr("class", "track_styles for_tooltip_left_position")
            // .attr(
            //   "style",
            //   `border: 1px solid ${this.darkDark ? "white" : "#c1c0c0"
            //   }`
            // )
            .style("background-color", "var(--colorBoxBg)")
            .attr("height", height_components + "px")
            .attr("width", width + "px");
          canvas = document.getElementById("rect" + div_id);
          let isAnnotation = curve_names.indexOf("alert") > -1;
          let isStickSlip = curve_names.indexOf("stickslipindex") > -1;
          let isWashout = curve_names.indexOf("washoutindex") > -1;

          canvas.addEventListener("mouseover", (event) => {
            this.mousemove(event, isAnnotation, isStickSlip, isWashout);
          });
          // Function to handle mousemove event and display tooltips
          canvas.addEventListener("mousemove", (event) => {
            this.mousemove(event, isAnnotation, isStickSlip, isWashout);
          });

          if (isAnnotation || isStickSlip || isWashout)
            canvas.addEventListener("click", (event) => {
            let foundArray = this.trackConfig.tracks.find((eachArr)=> eachArr.length && 
              eachArr[0]?.trackId == event.target.parentNode.id &&
              eachArr.some((eachObj)=> eachObj?.full_name == "ALERTS" || eachObj?.full_name == "STICK SLIP" || eachObj?.full_name == "WASH OUT")
            )
            if(foundArray){
              // TO HIDE THE HOVERING ALERT CODE STARTS 
              this.mouse_y = -1;
              this.mouse_x = -1;
              this.hoveringAlert = "";
              // TO HIDE THE HOVERING ALERT CODE ENDS
              
              for(let obj = 0; obj < foundArray.length; obj++){
                if(foundArray[obj]?.full_name == "ALERTS"){
                  // this.annotationClick(event, isAnnotation || isStickSlip || isWashout, 'ALERTS');
                  this.annotationClick(event, isAnnotation, 'ALERTS');
                  break;
                }
                else if(foundArray[obj]?.full_name == "STICK SLIP"){
                  this.annotationClick(event, isStickSlip, 'STICK SLIP');
                  break;
                }
                else if(foundArray[obj]?.full_name == "WASH OUT"){
                  this.annotationClick(event, isWashout, 'WASH OUT');
                  break;
                }
              }
            }

              // this.annotationClick(
              //   event,
              //   isAnnotation || isStickSlip || isWashout
              // );
            });

          // Function to handle mouseout event and clear the tooltip
          // canvas.addEventListener("mouseout", (event) => {
          //   this.mouse_y_tooltip = -1;
          //   this.tooltipUpdateIndex = null;
          // this.tooltip_horizontalline_ypos = null;
          // });

          // canvas.addEventListener("mousewheel", (event) => {
          //   this.mousescroll(event);
          // });

          this.zoom1 = d3
            .zoom()
            .scaleExtent([1, 20])
            .translateExtent([
              [0, 0],
              [width, height_components - 20],
            ])
            .extent([
              [0, 0],
              [width, height_components - 20],
            ])
            .on("zoom", this.zoomed);

          d3.select(canvas).call(this.zoom1);
          d3.select(canvas).on("wheel", (e) => {
            if (!this.isZoom) {
              this.mousescroll(e);
            }
          });
        }

        let mins = [];
        let maxes = [];

        let ctx = canvas.getContext("2d");
        let plotWidth = canvas.width;
        let plotHeight = canvas.height;

        let y = this.yLeftScale;
        if (this.isZoom && this.zoomed_Y_Axis) y = this.zoomed_Y_Axis;
        let drawTrend = () => {
          ctx.clearRect(0, 0, plotWidth, plotHeight);
          // Draw horizontal grid lines
          ctx.globalAlpha = 0.2;
          const numHorizontalLines =
            this.logType == "time" ? this.timeTicks : this.depthTicks;
          const stepY = plotHeight / (numHorizontalLines + 1);
          if (curve_names.indexOf("activitycode") == -1)
            for (let i = 1; i <= numHorizontalLines.length; i++) {
              // const y = i * stepY;
              ctx.beginPath();
              ctx.moveTo(0, y(numHorizontalLines[i]));
              ctx.lineTo(plotWidth, y(numHorizontalLines[i]));
              ctx.setLineDash([5, 5]); // An array of lengths for the dashes and gaps
              ctx.lineDashOffset = 0; // Offset for the dash pattern
              ctx.strokeStyle = "gray"; // Grid line color
              ctx.stroke();
            }

          // Draw vertical grid lines
          const numVerticalLines = 2;
          const stepX = plotWidth / (numVerticalLines + 1);
          for (let i = 1; i <= numVerticalLines; i++) {
            const x = i * stepX;
            ctx.beginPath();
            ctx.moveTo(x, 0);
            ctx.lineTo(x, plotHeight);
            ctx.strokeStyle = "gray"; // Grid line color

            ctx.stroke();
          }
          ctx.setLineDash([]); // An array of lengths for the dashes and gaps
          ctx.globalAlpha = 1;

          let graph_type = "time";
          if (this.logType == "depth") graph_type = "dmea";

          for (let k = 0; k < curve_names.length; k++) {
            try {
              if (curve_names[k] == "activitycode") {
                let startActcCode = 0;
                let endActcCode = 0;
                let startActcTime = 0;
                let endActcTime = 0;
                let totalRecords = data["activitycode"].length - 1;
                let actcColor = "white";
                let logType = this.logType;
                ctx.beginPath();
                for (let i = 1; i < data[curve_names[k]].length; i++) {
                  let d = data[curve_names[k]][i];
                  if (i == 0) {
                    startActcCode = d;
                    startActcTime = y(data[graph_type][i]);
                  } else {
                    endActcCode = d;
                    endActcTime = y(data[graph_type][i]);

                    if (
                      parseInt(startActcCode) != parseInt(endActcCode) ||
                      i == totalRecords
                    ) {
                      let tooltip = "";
                      switch (startActcCode) {
                        case 1:
                          actcColor = "#92D050";
                          tooltip = "ROT DRLG";
                          break;
                        case 2:
                          actcColor = "#00B050";
                          tooltip = "SLD DRLG";
                          break;
                        case 3:
                          actcColor = "#FFC000";
                          tooltip = "CIRC WITH ROT";
                          break;
                        case 4:
                          actcColor = "#FFFFFF";
                          tooltip = "UNKNOWN";
                          break;
                        case 10:
                          actcColor = "#833C0C";
                          tooltip = "CIRCULATION";
                          break;
                        case 11:
                          actcColor = "#00CC99";
                          tooltip = "REAMING";
                          break;
                        case 12:
                          actcColor = "#008000";
                          tooltip = "BACKREAM";
                          break;
                        case 13:
                          actcColor = "#CC3300";
                          tooltip = "CONNECTION";
                          break;
                        case 14:
                          actcColor = "#2F75B5"; // Bright Blue
                          tooltip = "TIH-DRLG";
                          break;
                        case 15:
                          actcColor = "#8EA9DB"; //Cadet Blue
                          tooltip = "POOH-DRLG";
                          break;
                        case 16:
                          actcColor = "#DBDBDB";
                          tooltip = "CIRC WITH ROT";
                          break;
                        case 17:
                          actcColor = "#FFFF00";
                          tooltip = "CIRCULATION";
                          break;
                        case 30:
                          actcColor = "#FFE699";
                          tooltip = "STATIONARY";
                          break;
                        case 5:
                          actcColor = "#1F4E78";
                          tooltip = "TIH";
                          break;
                        case 6:
                          actcColor = "#BDD7EE";
                          tooltip = "POOH";
                          break;
                        case 20:
                          actcColor = "#8497B0";
                          tooltip = "PUMPOUT";
                          break;
                        case 21:
                          actcColor = "#9900FF";
                          tooltip = "REAM-TRIP";
                          break;
                        case 22:
                          actcColor = "#FF3399";
                          tooltip = "BACK REAM-TRIP";
                          break;
                        case 23:
                          actcColor = "#CCCC00";
                          tooltip = "CONN. TRIP";
                          break;
                        case 24:
                          actcColor = "#CC9900";
                          tooltip = "CIRC W/O ROT-TRIP";
                          break;
                        case 25:
                          actcColor = "#FF9933";
                          tooltip = "CIRC W/O ROT-TRIP";
                          break;
                        case 26:
                          actcColor = "#833C0C";
                          tooltip = "CIRCULATION";
                          break;
                        case 27:
                          actcColor = "#945E0D";

                          tooltip = "WASHDOWN";
                          break;

                        default:
                          break;
                      }

                      ctx.lineWidth = width - 40;
                      ctx.beginPath();
                      ctx.moveTo(plotWidth / 2, startActcTime);
                      ctx.lineTo(plotWidth / 2, endActcTime);
                      ctx.strokeStyle = actcColor;
                      ctx.stroke();
                      if (logType == "time") {
                        startActcCode = d;
                        startActcTime = y(data[graph_type][i]);
                      } else {
                        startActcCode = d;
                        startActcTime = y(data[graph_type][i]);
                      }
                    } else {
                    }
                  }
                }
              } else if (curve_names[k] == "alert") {
                let alertTimes = [];
                let actcColor = "red";
                let lastEndTime = 0;
                let lastOffset = 18;
                function wrap(text, width) {
                  text.each(function (d) {
                    let text = d3.select(this),
                      words = text.text().split(/\s+/).reverse(),
                      word,
                      line = [],
                      lineNumber = 0,
                      lineHeight = 0.8, // ems
                      x = text.attr("x"),
                      y = text.attr("y"),
                      dy = 0,
                      tspan = text
                        .text(null)
                        .append("tspan")
                        .attr("x", x)
                        .attr("y", y)
                        .attr("dy", dy + "em");
                    while ((word = words.pop())) {
                      line.push(word);
                      tspan.text(line.join(" "));

                      if (tspan.node().getComputedTextLength() > width) {
                        line.pop();
                        tspan.text(line.join(" "));
                        line = [word];
                        tspan = text
                          .append("tspan")
                          .attr("x", x)
                          .attr("y", y)
                          .attr("dy", ++lineNumber * lineHeight + dy + "em")
                          .text(word);
                      }
                    }
                  });
                }
                let theme = this.darkDark;
                function drawAlert(istance, alerts, isPair, i) {
                  if (!lastEndTime) {
                    lastEndTime =
                      y(alerts[0].time) +
                      (y(alerts[alerts.length - 1].time) - y(alerts[0].time)) /
                        2;
                  } else {
                    let newPlacement =
                      y(alerts[0].time) +
                      (y(alerts[alerts.length - 1].time) - y(alerts[0].time)) /
                        2;

                    lastEndTime += lastOffset;
                    if (newPlacement > lastEndTime) {
                      lastEndTime = newPlacement;
                    }
                  }

                  let message = alerts[0].msg.split(",");
                  let messageLength = message.length;
                  for (let alertC = 0; alertC < messageLength; alertC++) {
                    let textMessage = message[alertC];
                    if (alertC) lastEndTime += lastOffset;
                    if (isPair) {
                      ctx.lineWidth = 1;
                      ctx.beginPath();
                      ctx.moveTo(0, y(alerts[0].time));
                      ctx.lineTo(18, lastEndTime);
                      ctx.stroke();
                      ctx.lineWidth = 1;
                      ctx.beginPath();
                      ctx.moveTo(0, y(alerts[alerts.length - 1].time));
                      ctx.lineTo(18, lastEndTime);
                      ctx.strokeStyle = "red";
                      ctx.stroke();
                    } else {
                      ctx.lineWidth = 1;
                      ctx.beginPath();
                      ctx.moveTo(0, y(alerts[0].time));
                      ctx.lineTo(18, lastEndTime);
                      ctx.strokeStyle = "red";
                      ctx.stroke();
                    }

                    let rectX = 20 + 5;
                    let rectY = lastEndTime;
                    let rectWidth = width - 20;

                    let maxTextWidth = rectWidth - 20; // Leave some padding for the text
                    let textX = rectX + 0; // Start with some padding
                    let textY = rectY + 2.5; // Start with some padding
                    const lineHeight = 8;

                    function wrapText(text, isText) {
                      let rect_height = 0;
                      const words = text.split(" ");
                      let line = "";
                      for (let n = 0; n < words.length; n++) {
                        const testLine = line + words[n] + " ";
                        const testWidth = ctx.measureText(testLine).width;
                        if (testWidth > maxTextWidth && n > 0) {
                          if (isText) ctx.fillText(line, textX, textY);
                          else rect_height++;
                          line = words[n] + " ";
                          textY += lineHeight;
                        } else {
                          line = testLine;
                        }
                      }
                      if (isText) ctx.fillText(line, textX, textY);
                      else rect_height++;

                      return rect_height * lineHeight;
                    }

                    ctx.shadowColor = "rgba(0, 0, 0, 0.5)";
                    ctx.shadowOffsetX = 5;
                    ctx.shadowOffsetY = 5;
                    ctx.shadowBlur = 5;
                    ctx.fillStyle = "white";
                    let height = wrapText(textMessage) + 4;
                    ctx.fillRect(20, lastEndTime - 7, width - 20, height);
                    alertTimes.push({
                      y: lastEndTime - 18,
                      yEnd: lastEndTime - 15 + height,
                      alert: textMessage,
                    });
                    maxTextWidth = rectWidth - 20; // Leave some padding for the text
                    textX = rectX + 0; // Start with some padding
                    textY = rectY + 2.5; // Start with some padding

                    ctx.shadowColor = "transparent"; // Reset shadow for text
                    ctx.fillStyle = "red";
                    wrapText(textMessage, true);
                  }
                }
                let alerts = [];

                for (let i = 0; i < data["alert"].length; i++) {
                  let d = data["alert"][i];
                  alerts.push({ msg: d, index: i, time: data["time"][i] });
                  if (alerts[0].msg) {
                    if (alerts[0].msg == data["alert"][i + 1]) {
                    } else {
                      if (alerts.length > 1) {
                        drawAlert(this, alerts, true, i);
                        alerts = [];
                      } else {
                        drawAlert(this, alerts, false, i);
                        alerts.shift();
                      }
                    }
                  } else {
                    alerts = [];
                  }
                }
                this.alertTimes = alertTimes;
              } else {
                let min = mins[k];
                let max = maxes[k];
                let header_text_line = "";
                if (min != null && max != null) {
                  header_text_line =
                    parseFloat(min).toFixed(1) +
                    " - " +
                    curve_names[k] +
                    "  " +
                    curveUnit +
                    " - " +
                    parseFloat(max).toFixed(1);
                }
                let curveScale = curveScales[k];
                let min_this = "";
                let max_this = "";
                let scaleType = curveScaleType[k];
                if (
                  scaleType == "manual" &&
                  (curveScale != null || curveScale != undefined)
                ) {
                  if (curveScale[0] != null || curveScale[0] != "") {
                    min_this = curveScale[0];
                  } else {
                    // min_this = this.minmax[curve_names[k]].min
                    min_this = d3.min(this.data[curve_names[k]]);
                    curveScale[0] = min_this;
                  }
                  if (curveScale[1] != null || curveScale[1] != "") {
                    max_this = curveScale[1];
                  } else {
                    // max_this = this.minmax[curve_names[k]].max
                    max_this = d3.max(this.data[curve_names[k]]);
                    curveScale[1] = max_this;
                  }
                } else {
                  let currentRange = defaultRanges.ranges.find(
                    (data) => data.name == curve_names[k]
                  );
                  if (currentRange) {
                    max_this = currentRange.high;
                    min_this = currentRange.low;
                  } else {
                    min_this = d3.min(this.data[curve_names[k]]);
                    max_this = d3.max(this.data[curve_names[k]]);
                  }
                  curveScale[0] = min_this;
                  curveScale[1] = max_this;
                }

                if (min_this == max_this && max_this == 0) {
                  max_this = 1;
                  min_this = 0;
                }

                let x = d3
                  .scaleLinear()
                  .domain([min_this, max_this])
                  .range([margin.left, width - margin.right]);

                //// This creates an object to hold multiple x axis scale functions
                //// that will be used if 'between' style fill is selected.

                //////////////  Header text, two way depending on  =>//////////////

                ctx.lineWidth = template_curves["stroke_width"][k];
                ctx.beginPath();
                ctx.moveTo(x(data[curve_names[k]][0]), 0);
                let stickslip = [];
                let washout = [];
                let stickslip_msg = [];
                let washout_msg = [];
                let lastMsgTime_s = "";
                let lastMsgTime_w = "";
                let skipInterval;
                switch (Number(this.currentTimeRange)) {
                  case 1:
                    skipInterval = 3 * 300000;
                    break;
                  case 2:
                    skipInterval = 3 * 300000;
                    break;
                  case 4:
                    skipInterval = 12 * 300000;
                    break;
                  case 8:
                    skipInterval = 6 * 300000;
                    break;
                  case 12:
                    skipInterval = 9 * 300000;
                    break;
                  case 24:
                    skipInterval = 12 * 300000;
                    break;

                  default:
                    skipInterval = 2 * 300000;
                    break;
                }
                for (let i = 1; i < data[curve_names[k]].length; i++) {
                  ctx.lineTo(
                    x(data[curve_names[k]][i]),
                    y(data[graph_type][i])
                  );
                  if (
                    curve_names[k] == "stickslipindex" &&
                    data["stickslip"][i] &&
                    data["stickslip"][i] != "NONE" &&
                    (lastMsgTime_s == "" ||
                      lastMsgTime_s + skipInterval < data[graph_type][i])
                  ) {
                    lastMsgTime_s = data[graph_type][i];
                    stickslip.push(y(data[graph_type][i]) + 10);
                    stickslip_msg.push(data["stickslip"][i]);
                  }
                  if (
                    curve_names[k] == "washoutindex" &&
                    data["washout"][i] &&
                    data["washout"][i] != "NONE" &&
                    (lastMsgTime_w == "" ||
                      lastMsgTime_w + skipInterval < data[graph_type][i])
                  ) {
                    lastMsgTime_w = data[graph_type][i];
                    washout.push(y(data[graph_type][i]));
                    washout_msg.push(data["washout"][i]);
                  }
                }
                ctx.strokeStyle = curve_colors[k];

                if (curve_names[k] == "depthofcut") {
                  ctx.lineTo(
                    x(0),
                    y(data[graph_type][data[graph_type].length - 1])
                  );
                  ctx.lineTo(x(0), 0);
                  ctx.lineTo(
                    x(data[curve_names[k]][0]),
                    y(data[graph_type][0])
                  );
                  ctx.fillStyle = curve_colors[k];
                  ctx.closePath();
                  // Fill the area under the line graph
                  ctx.fill();
                }

                ctx.stroke();
                let rectX = 10;
                let rectWidth = width - 20;
                let maxTextWidth = rectWidth - 20; // Leave some padding for the text
                let textX = rectX + 0; // Start with some padding
                const lineHeight = 8;
                function wrapText(text, isText, rectY) {
                  let textY = rectY; // Start with some padding
                  let rect_height = 0;
                  const words = text.split(" ");
                  let line = "";
                  for (let n = 0; n < words.length; n++) {
                    const testLine = line + words[n] + " ";
                    const testWidth = ctx.measureText(testLine).width;
                    if (testWidth > maxTextWidth && n > 0) {
                      if (isText) {
                        ctx.fillText(line, textX, textY);
                      } else rect_height++;
                      line = words[n] + " ";
                      textY += lineHeight;
                    } else {
                      line = testLine;
                    }
                  }
                  if (isText) {
                    ctx.fillText(line, textX, textY);
                  } else rect_height++;

                  return rect_height * lineHeight;
                }
                if (curve_names[k] == "stickslipindex")
                  this.alertStickslip = [];
                for (let s = 0; s < stickslip.length; s++) {
                  // ctx.globalAlpha = 0.4;
                  ctx.shadowColor = "rgba(0, 0, 0, 0.5)";
                  ctx.shadowOffsetX = 5;
                  ctx.shadowOffsetY = 5;
                  ctx.shadowBlur = 5;
                  ctx.fillStyle = "yellow";
                  let height = wrapText(stickslip_msg[s]) + 4;
                  ctx.fillRect(0, stickslip[s] - 10, width, height);
                  this.alertStickslip.push({
                    y: stickslip[s] - 20,
                    yEnd: stickslip[s] - 15 + height,
                    alert: stickslip_msg[s],
                  });
                  // ctx.globalAlpha = 1;
                  ctx.fillStyle = "red";
                  // ctx.fillText(stickslip_msg[s], 0, stickslip[s]);
                  wrapText(stickslip_msg[s], true, stickslip[s]);
                }
                if (curve_names[k] == "washoutindex") this.alertWashout = [];
                for (let w = 0; w < washout.length; w++) {
                  // ctx.globalAlpha = 0.4;
                  ctx.shadowColor = "rgba(0, 0, 0, 0.5)";
                  ctx.shadowOffsetX = 5;
                  ctx.shadowOffsetY = 5;
                  ctx.shadowBlur = 5;
                  ctx.fillStyle = "yellow";
                  let height = wrapText(washout_msg[w]) + 4;
                  ctx.fillRect(0, washout[w] - 10, width, height);
                  this.alertWashout.push({
                    y: washout[w] - 20,
                    yEnd: washout[w] - 15 + height,
                    alert: washout_msg[w],
                  });
                  // ctx.globalAlpha = 1;
                  ctx.fillStyle = "red";
                  // ctx.fillText(washout_msg[w], 0, washout[w]);
                  wrapText(washout_msg[w], true, washout[w]);
                }

                stickslip = [];
                washout = [];
                stickslip_msg = [];
                washout_msg = [];
              }
            } catch (error) {
              console.error(
                "DisTrend.vue:4377 curve_names[k] ⏩",
                curve_names[k],
                error
              );
            }
          }
        };
        drawTrend();
      } catch (error) {
        console.error(
          "🚀 ➽ file: DisTrend.vue:3933  ➽ curveBox  ➽ error ⏩",
          template_for_plotting,
          index,
          divId,
          error
        );
      }
      // console.timeEnd("curve box divId " + divId);
      if (this.plots.length - 1 == index) {
        this.isRerendering = false;
      }
    },
    getConfig() {
      let tconfig = {
        height: "500",
        width: "260",
        UWI: "00/01-01-095-19W4/0",
        curveName: "CHALKTALK",
        tracks: this.tracks,
        curveType: "dmea",
        depthName: "dmea",
      };
      this.trackConfig = tconfig;
      return tconfig;
    },
    async configure() {
      let UWI = this.trackConfig.UWI;
      // let curveNames = curveNames;
      // let curveColors=curveColors;
      let curveName = this.trackConfig.curveName;
      let _plots = [];
      let mapping = await helperServices.getIndexDetails(this.wellId);
      mapping = mapping.data.logs[this.logType];

      for (let i = 0; i < this.trackConfig.tracks.length; i++) {
        let track = this.trackConfig.tracks[i];
        let curveNames = track.map((curve) => curve.name);
        let curveColors = track.map((curve) => curve.color);

        let fillColor = track.map((curve) => curve.fillC);
        let fillDir = track.map((curve) => curve.fillD);
        let fill = track.map((curve) => curve.fill);
        let strokes = track.map((curve) => curve.stroke || 2);
        let units = track.map((curve, index) => {
          track[index].unit = "";
          track[index].full_name = "";
          if (mapping.tags.indexOf(curve.name) != -1) {
            track[index].unit = mapping.units[mapping.tags.indexOf(curve.name)];
            // track[index].full_name =
            //   mapping.displayTags[
            //     mapping.tags.indexOf(curve.name)
            //   ].toUpperCase();

            if (curve.name == "diff") track[index].full_name = "DIFF PRESS";
            else if (curve.name == "simplifiedmse")
              track[index].full_name = "MSE";
            else if (curve.name == "bpos") track[index].full_name = "BLOCK POS";
            else if (curve.name == "stickslipindex")
              track[index].full_name = "STICK SLIP";
            else if (curve.name == "washoutindex")
              track[index].full_name = "WASH OUT";
            else if (curve.name == "alert") track[index].full_name = "ALERTS";
            else if (curve.name == "depthofcut")
              track[index].full_name = "DEPTH OF CUT";
            else
              track[index].full_name =
                mapping.displayTags[
                  mapping.tags.indexOf(curve.name)
                ].toUpperCase();
          }
          return mapping.units[mapping.tags.indexOf(curve.name)];
        });
        let scale = track.map((curve) => curve.scale);
        let alert = track.map((curve) => curve.alert);
        let isAlert = track.map((curve) => curve.isAlert);
        let decimals = track.map((curve) => curve.decimals);
        let scaleType = track.map((curve) => curve.scaleType);
        let trackId;
        if (track.length > 0) {
          trackId = track[0].trackId;
        }

        let trackObjs = [];
        for (let j = 0; j < track.length; j++) {
          let configObj = {
            curve_name: "",
            fill: "",
            fill_direction: "",
            cutoffs: [],
            fill_colors: [],
          };
          configObj.curve_name = track[j].name;
          configObj.fill_direction = track[j].fillD;
          configObj.fill = track[j].fill;
          configObj.cutoffs = [0, 4.25];
          configObj.fill_colors = [track[j].fillC];
          trackObjs.push(configObj);
          trackObjs = trackObjs;
        }
        if (this.singleTrackWidth > this.trackWidth) {
          this.singleTrackWidth = this.trackWidth;
        }
        let graphType = "dmea";
        this.logType == "time" ? (graphType = "time") : null;
        let plot = plotConfig(
          this.plotTemplate,
          UWI,
          curveNames,
          curveColors,
          units,
          strokes,
          scale,
          scaleType,
          trackObjs,
          this.div_id,
          this.singleTrackWidth,
          this.singleTrackHeight,
          graphType,
          this.tickColor,
          this.tickThickness,
          "#202124", //leftbarbg = ,
          "white", // tooltipbg = ,
          trackId,
          alert,
          decimals,
          isAlert
        );
        _plots.push(plot);
      }

      this.plots = _plots;
    },
    async getSingleTagData(tagName, isUpdate, from) {
      this.getMnemonicType(tagName);
      this.$store.dispatch("data/startLoading");
      let result = null;
      let dconfig = {};

      if (isUpdate) dconfig.fields = tagName;
      else dconfig.fields = [tagName];

      dconfig.wellId = this.wellId;
      dconfig.wellboreId = this.wellboreId;
      dconfig.logId = this.logId;
      dconfig.dataPoints = null;
      dconfig.depthRange = null;

      try {
        if (this.logType == "time") {
          let fieldArr =
            tagName == "dmea" ? [tagName, "alert"] : [tagName, "dmea", "alert"];
          if (fieldArr.indexOf("stickslipindex") > -1)
            fieldArr.push("stickslip");
          if (fieldArr.indexOf("washoutindex") > -1) fieldArr.push("washout");
          for (let i in this.mnemonic_groups) {
            result = await apiService.WellServices.getTimeLogs({
              fields: this.mnemonic_groups[i],
              well_id: this.wellId,
              wellbore_name: this.wellboreId,
              isParse: 1,
              type: i,
              from: this.scaleMax,
              to: this.scaleMin,
              topic:this.$store.state.disp?.displays[this.displayId]?.topicName
            });
            if (result && result.data && result.data.data) {
              if (
                result.data.data[this.mnemonic_groups[i][0]] &&
                result.data.data[this.mnemonic_groups[i][0]].length == 0
              ) {
                // this.$toast.error("Data is not available for this interval", {
                //   position: "top",
                //   duration: 3000,
                // });
              } else {
                this.completeData = {
                  ...this.completeData,
                  ...result.data.data,
                };
                this.data = this.completeData;
                this.minmax = { ...this.minmax, ...result.data.minmax };
              }
            } else {
              // this.$toast.error("Server is taking longer than usual", {
              //   position: "top",
              //   duration: 3000,
              // });
            }
          }
        } else {
          console.log("getSingleTagData called");
          apiService.WellServices.getDepthLogs({
            fields: dconfig.fields,
            well_id: this.wellId,
            wellbore_name: this.wellboreId,
            // isPartial: false,
          })
            .then(async (data) => {
              console.log("---------- whole data came 2 ----------");
              if (data && data.data && data.data.data) {
                console.log(data.data);
                this.completeData = { ...this.completeData, ...data.data.data };
              }
            })
            .catch((e) => {
              console.error("whole data error : ", e);
            });
          result = await apiService.WellServices.getDepthLogs({
            fields: dconfig.fields,
            well_id: this.wellId,
            wellbore_name: this.wellboreId,
            isPartial: true,
            start: this.scaleMin,
            end: this.scaleMax,
          });

          // result = result.data;

          // if (isUpdate) {
          //   for (let i of tagName) {
          //     await this.addData(result, i);
          //   }
          // } else {
          //   result = await this.addData(result, tagName);
          // }
          if (result && result.data && result.data.data) {
            if (
              result.data.data["dmea"] &&
              result.data.data["dmea"].length == 0
            ) {
              this.$toast.error("Data is not available for this well", {
                position: "top",
                duration: 3000,
              });
            }
          }
          this.completeData = { ...this.completeData, ...result.data.data };
          this.data = { ...this.completeData };
          this.minmax = { ...this.minmax, ...result.data.minmax };
        }
      } catch (error) {
        console.error(error);
      }
      setTimeout(() => {
        this.$store.dispatch("data/stopLoading");
      }, 700);

      // }
      return result;
    },
    async getMinMaxRange() {
      try {
        let result = null;
        if (this.logType == "time") {
          result = await apiService.WellServices.getMinMaxTime({
            well_id: this.wellId,
            wellbore_name: this.wellboreId,
          });
          console.log(
            "time zone difference : ",
            result.data.min,
            moment(result.data.min).unix() * 1000
          );
          result.data.min = moment(result.data.min).unix() * 1000;
          result.data.max = moment(result.data.max).unix() * 1000;
        } else {
          result = await apiService.WellServices.getMinMaxDepth({
            well_id: this.wellId,
            wellbore_name: this.wellboreId,
          });
          result.data.min = result.data.min;
          result.data.max = result.data.max;
        }

        return result.data;
      } catch (error) {
        console.error(error);
        return { min: 0, max: 1000 };
      }
    },
    async getItFromLocalData(range, isLogChange) {
      if (this.logType == "depth") {
        console.log("complete data befor ", this.completeData, isLogChange);
        if (isLogChange) {
          let fields = this.getOnScreenMnemonics();

          await this.getMultiTagsData(fields, "getItFromLocalData");

          this.rerender("getItFromLocalData depth");
        } else {
          let graphType = "dmea";
          this.logType == "time" ? (graphType = "time") : null;

          if (!this.completeData[graphType]) {
            let fields = this.getOnScreenMnemonics();
            await this.getMultiTagsData(fields, "getItFromLocalData");
          }

          this.completeData[graphType].map((row, i) => {
            if (
              row == range[0] ||
              (row < range[0] && !(row > range[0])) ||
              (range[0] == 0 && i == 0)
            ) {
              this.lowerIndex = i;
            }

            if (row == range[1] || row < range[1]) {
              this.upperIndex = i;
            }
          });
          let tempData = { ...this.completeData };

          for (let i in tempData) {
            this.data[i] = tempData[i].slice(
              this.lowerIndex,
              this.upperIndex + 1
            );
          }
        }
      } else {
        let fields = this.getOnScreenMnemonics();
        await this.getMnemonicType(fields);
        if (!this.liveDisabled || this.data.length == 0) {
          this.$store.dispatch("data/startLoading");
          for (let i in this.mnemonic_groups) {
            // if(thisgraph is replay)
            let result = await apiService.WellServices.getTimeLogs({
              fields: this.mnemonic_groups[i],
              well_id: this.wellId,
              wellbore_name: this.wellboreId,
              secondsdataavailability: this.secondsdataavailability,
              isParse: 1,
              type: i,
              // days: 0.2,
              from: parseInt(range[1]),
              to: parseInt(range[0]),
              topic:this.$store.state.disp?.displays[this.displayId]?.topicName
            });
            if (result && result.data && result.data.data) {
              if (
                result.data.data[this.mnemonic_groups[i][0]] &&
                result.data.data[this.mnemonic_groups[i][0]].length == 0
              ) {
                // this.$toast.error("Data is not available for this interval", {
                //   position: "top",
                //   duration: 3000,
                // });
              } else {
                this.completeData = {
                  ...this.completeData,
                  ...result.data.data,
                };
                this.data = this.completeData;
                this.minmax = { ...this.minmax, ...result.data.minmax };
              }
            } else {
              // this.$toast.error("Server is taking longer than usual", {
              //   position: "top",
              //   duration: 3000,
              // });
            }
          }
          setTimeout(() => {
            this.$store.dispatch("data/stopLoading");
          }, 700);
        } else {
        }
      }
    },
    async zoomIt(depthMax, depthMin, feet, isLogChange, from) {
      if (depthMax == depthMin) {
        return false;
      }
      let totalRows;
      let depthRange;

      if (depthMax > depthMin) {
        totalRows = Math.round(depthMax - depthMin);
        depthRange = [depthMin, depthMax];
      } else {
        totalRows = Math.round(depthMin - depthMax);
        depthRange = [depthMax, depthMin];
      }
      // console.log("depthRange : ", depthRange);
      if (!this.isReplay)
        await this.getItFromLocalData(depthRange, isLogChange, from);

      this.rerender("zoomIt");
    },
    async rerender(from) {
      // console.log("delete rerender checkl : ", this.isRerendering);
      if (this.isRerendering) {
        return 0;
      }
      this.isRerendering = true;
      if (this.trackConfig.tracks)
        this.setTrackWidth(this.trackConfig.tracks.length);
      else this.setTrackWidth(0);
      this.setTrackHeight();
      this.trackConfig = await this.getConfig();
      if (this.trackConfig.tracks && this.trackConfig.tracks.length) {
        this.tracksNbr = this.trackConfig.tracks.length;
        if (this.tracksNbr > 0) {
          if (this.trackConfig.tracks[0][0].name != null) {
            this.readyToPlot = true;
            this.emptyPlot = false;

            this.configurePlots = await this.configure();
            if (this.plots)
              // setTimeout(() => {
              this.verticalplots(this.div_id, this.plots, true);
            // }, 0);

            let sliderId = "slider" + this.compId;
            let sliderEle = document.querySelector("#" + sliderId);
            if (sliderEle != null) {
              sliderEle.style.height = this.singleTrackHeight - 60 + "px";
            }
            // this.setTrackInfo();
          }
        }
      } else {
        this.isRerendering = false;
      }
    },
    deleteTrack() {
      this.showCurveInfo = false;
      trackDeleteDisabled = true;

      this.trackConfig.tracks.splice(trackNbr, 1);
      this.trackConfig.tracks = this.trackConfig.tracks;
      this.trackConfig = this.trackConfig;
      // this.getTracksRight();
      // this.adjustTracks();
      this.configure();

      this.verticalplots(this.div_id, this.plots, true);

      // this.setTrackInfo();
    },
    addTrack() {
      let trackId = "t" + Math.random().toString(36).slice(8);

      this.readyToPlot = true;
      this.emptyPlot = false;
      this.isNewTrack = true;
      this.showCurveInfo = false;
      this.curveDeleteDisabled = true;
      this.newCurveDisabled = true;
      if (this.trackConfig == null) {
        // getConfig();
      }
      // getConfig();
      // if (this.trackConfig.tracks.length == 0 ) {
      //
      //     tracks = [];
      //     this.trackConfig.tracks = tracks;
      // }
      // let trackObj = {};
      // trackObj.trackId = trackId;

      this.tracks.push([]);
      this.tracks = this.tracks;
      // this.trackConfig.tracks = tracks;
      if (this.tracks[0].length == 0) {
        this.readyToPlot = false;
      }
      // if (tracks[0].length == 0) {
      //     readyToPlot = false;
      // }

      this.getConfig();
      this.trackConfig.tracks = this.tracks;
      // this.trackConfig.tracks.push([]);
      // this.trackConfig.tracks = this.trackConfig.tracks;
      this.trackConfig = this.trackConfig;
      let currentCurve = {};
      let trackLength = this.trackConfig.tracks.length;
      let trackNbr = trackLength - 1;
      // this.getTracksRight(trackNbr, trackId);
      this.selectedTrackValue = this.totTracks[trackNbr];

      this.newCurveDisabled = false;
      // this.setTrackInfo();
      return trackId;
    },
    changeTooltip() {
      let tracks = document.querySelectorAll(".for_tooltip_left_position");
      this.tracks_left_position = Object.values(tracks).map(
        (each) => each.getBoundingClientRect().left
      );
      this.isShowTooltip = !this.isShowTooltip;
      if (!this.isShowTooltip) {
        for (let focusAxis of this.focusAxisList) {
          let focus = focusAxis["focus"];
          focus
            .style("display", "none")
            .transition()
            .duration(250)
            .style("opacity", 0);
        }
      }
      this.tooltip_horizontalline_ypos = null;
      // if(this.isZoom = true){
      //   this.isShowTooltip = false
      // }
    },
    changeZoom() {
      if (this.isZoom && this.zoomed_Y_Axis) {
        if (this.logType == "depth") {
          // this.scaleMax = this.zoomed_Y_Axis.domain()[0];
          // this.scaleMin = this.zoomed_Y_Axis.domain()[1];
          this.currentFeetRange = Number(this.scaleMax - this.scaleMin).toFixed(
            2
          );
        } else {
          // this.scaleMax = moment(this.zoomed_Y_Axis.domain()[0]).unix() * 1000;
          // this.scaleMin = moment(this.zoomed_Y_Axis.domain()[1]).unix() * 1000;
          // this.currentTimeRange = Number(
          //   (this.scaleMax - this.scaleMin) / (60 * 60 * 1000)
          // ).toFixed(2);
        }
      } else {
      }
      this.isZoom = !this.isZoom;

      this.zoomed_Y_Axis = null;
      // this.createLeftBar(this.div_id, this.plots);
      // this.rerender();
      // this.zoomIt(this.scaleMax, this.scaleMin, this.feet, false, "update 14091");
      this.setAutoPlay(!this.isAuto);
    },
    changeAlert() {
      this.isShowAlerts = !this.isShowAlerts;
      this.rerender();
    },
    replayDataIntervalChangeHandler() {
      // let tempInterval = this.replayDataInterval;
      // if(logInterval && logInterval=='5_sec'){
      //   tempInterval = this.replayDataInterval/5;
      // }
      // console.log("stream speed:",tempInterval);
      this.changeNumberOfrecordTobeDisplay(this.replayDataInterval);
    },
  },
  watch: {
    width(newValue) {
      this.rerender("width");
    },
    height(newValue) {
      this.rerender("height");
    },
    replayDetails(result) {
      // console.log('replay result::',result);
      if (result) {
        if (this.logType == "time") {
          if (this.data["time"]) {
            if (
              this.data["time"][this.data["time"].length - 1] <
                result["time"][result["time"].length - 1] &&
              this.data["time"][this.data["time"].length - 1] <
                (result["time"].length > 1 ? result["time"][1] : result["time"][0])
            ) {
              for (let i in this.completeData) {
                if (result[i]) {
                  this.completeData[i] = [
                    ...this.completeData[i],
                    ...result[i],
                  ];
                }
              }
              // console.log(
              //   "if part time check : ",
              //   result["time"][0],
              //   result["time"].length,
              //   this.data["time"].length
              // );
            } else {
              // this.scaleMax = result["dmea"][result["dmea"].length - 1];
              // for (let i in this.completeData) {
              //     if (result[i]) {
              //       this.completeData[i] = [
              //         ...this.completeData[i],
              //         ...result[i],
              //       ];
              //     }
              //   }
              // console.log("else part time check : ", result["time"][0], result["time"].length, this.data['time'].length)
            }
            this.data = this.completeData;
          } else {
            this.data = result;
            this.completeData = result;
            // ?console.log("full replace part time check ", result["time"][0])
          }
        }
        if (this.logType == "time") {
          this.data = this.completeData;
          if (this.data["time"] && this.data["time"].length) {
            if (
              this.data["time"][this.data["time"].length - 1] -
                this.data["time"][0] >
              3600000 * this.currentTimeRange
            ) {
              this.scaleMin =
                this.data["time"][this.data["time"].length - 1] -
                3600000 * this.currentTimeRange;
              this.scaleMax = this.data["time"][this.data["time"].length - 1];
              this.rangeInput = this.scaleMax;
              this.scaleMinDate = new Date(this.scaleMin).toLocaleString("en-US", {
                  timeZone: this.time_zone,
                });
            } else {
              this.scaleMin = this.data["time"][0];
              this.scaleMax =
                this.data["time"][0] + 3600000 * this.currentTimeRange;
                this.scaleMinDate = new Date(this.scaleMin).toLocaleString("en-US", {
                  timeZone: this.time_zone,
                });
            }
            // if (this.completeData["time"].length > 28000) {
            //   for (let i in this.completeData) {
            //     //check data size
            //     this.completeData[i].splice(4000);
            //     this.data[i].splice(4000);
            //   }
            // }
          }
        }
        this.zoomIt(
          this.scaleMax,
          this.scaleMin,
          this.feet,
          false,
          "update live"
        );
      }
    },
    isActive(newVal){
      if(newVal && this.isAuto){
        this.setAutoPlay(false);
        console.log('Replay is stoppped');
      }
    }
  },
  setup() {
    const {
      isAuto,
      replayDetails,
      setAutoPlay,
      removeruningIntervals,
      changeDateReplay,
      changeHouesReplay,
      changeNumberOfrecordTobeDisplay,
    } = useReplay();
    return {
      isAuto,
      replayDetails,
      setAutoPlay,
      removeruningIntervals,
      changeDateReplay,
      changeHouesReplay,
      changeNumberOfrecordTobeDisplay,
    };
  },
  unmounted() {
    this.removeruningIntervals();
  },
};
</script>

<style scoped>
.colorbox {
  width: 5px;
  height: 100%;
}

.zoomin {
  width: 50px;
  background: lightgoldenrodyellow;
  position: absolute;
  bottom: 0;
}

.textBgColor {
  background: yellow;
  background-color: yellow;
}

.area {
  fill: lightsteelblue;
}

/* .scrolldiv {
  } */
.actc_pop {
  position: absolute;
  left: -101px;
}

/* hover tooltip */

.tool_group {
  position: fixed;
  display: inline-grid;
  right: 162px;
  z-index: 99999;
}

.tooltip_actc {
  position: absolute;
  display: block;
  height: 100%;
}

.tooltip_actc .tooltiptext {
  visibility: hidden;
  color: #fff;
  text-align: center;
  border-radius: 12px;
  position: absolute;
  z-index: 1;
  top: -5px;
  right: 105%;
}

.tooltip_actc .tooltiptext::after {
  content: "";
  position: absolute;
  right: -6px;
  top: 5px;
  content: "\1F782";
  font-size: 14px;
  color: #005e62;
}

.tooltip_actc .tooltiptext {
  visibility: visible;
  background: #0e1016;
  background: #00071a;
  z-index: 999999;
}

.tooltiptext {
  /* background: var(--nav1BgClrsame) !important; */
  padding: 2vmin 2vmin;
  padding-bottom: 1vmin;
}

.grid-container {
  display: grid;
  grid-template-columns: auto auto;
  /* grid-gap: 2px; */
  padding: 0;
  justify-content: center;
  float: right;
}

.grid-container > div {
  text-align: center;
  padding: 5px 10px;
  font-size: 30px;
  margin: 0 auto;
  width: 330px;
}

.grid-container > .drill_eff_item_3 {
  width: 850px;
}

.grid-container > .drill_eff_item_1 {
  width: 850px;
}

/* .item1 {
  grid-column: 1 / 2;
} */
.item1 {
  grid-column: 2 / 2;
}

._item_ {
  height: auto;
  max-height: 22em;
  border-radius: 12px;
}

._item_:last-child {
  grid-column: 2;
}

._item_ > img {
  height: 100%;
}

.t_d_col_1 {
  /* width: 20%;
  display: flex;
  justify-content: end; */
}

.t_d_col_2 {
  width: 80%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.td_trnk {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: calc((0.3em + 0.3vmin) + (0.2em + 0.2vmax));
}

.text_gray {
  color: #dee2e6;
}

.text_dark {
  /* color: #000000; */
  font-weight: 600;
}

.t_d_col_3 {
  width: 25%;
}

.t_d_row_1 {
  /* font-size: 8px; */
  /* font-weight: bold; */
  font-size: 9px;
  letter-spacing: 0.5px;
  font-weight: 600 !important;
  padding-top: 4px;
}

.t_d_row_2 {
  /* position: relative;
  bottom: -2px;
  font-size: 7.9px;
  font-weight: bold; */
  position: relative;
  bottom: 2px;
  font-size: 9px;
  font-weight: 500;
  letter-spacing: 0.5px;
}

.tooltip__tip {
  text-align: center;
}

.tooltip__tip::after {
  display: none;
  padding: 10px 15px;
  position: absolute;
  text-align: center;
  z-index: 999;
  color: white;
}

.tooltip__tip::before {
  content: " ";
  display: none;
  position: absolute;
  width: 7px;
  height: 7px;
  z-index: 999;
}

.tooltip__tip_dark::after {
  background: #1b1a1f !important;
  color: #ffffff;
}

.tooltip__tip_dark::before {
  background: #1b1a1f !important;
}

.tooltip__tip_light::after {
  background: #99fad7 !important;
  color: #000000 !important;
}

.tooltip__tip_light::before {
  background: #99fad7 !important;
  color: #1b1a1f !important;
}

.tooltip__tip:hover::after {
  display: block;
}

.tooltip__tip:hover::before {
  display: block;
}

.tooltip__tip.top::after {
  content: attr(data-tip);
  top: 7px;
  left: 50%;
  width: auto;
  font-size: min(1vw, 9px);
  /* calc(0.2vw + 0.2vh + 0.65vmin); */
  padding: 3px 14px;
  transform: translate(-50%, calc(-100% - 10px));
}

.tooltip__tip.top::before {
  top: 5px;
  left: 50%;
  transform: translate(-50%, calc(-100% - 5px)) rotate(45deg);
}

.tool_tip_units_r.top::after {
  content: attr(data-tip);
  top: 2px;
  left: 81%;
  padding: 3px 14px;
  font-size: 7px;
  -webkit-transform: translate(-50%, calc(-100% - 10px));
  transform: translate(-50%, calc(-100% - 10px));
}

.tool_tip_units_r.top::before {
  top: 0;
  left: 90%;
  transform: translate(-50%, calc(-100% - 5px)) rotate(45deg);
}

.tool_tip_units_l.top::after {
  content: attr(data-tip);
  top: 2px;
  left: 16%;
  padding: 3px 14px;
  font-size: 7px;
  -webkit-transform: translate(-50%, calc(-100% - 10px));
  transform: translate(-50%, calc(-100% - 10px));
}

.tool_tip_units_l.top::before {
  top: 0;
  left: 16%;
  transform: translate(-50%, calc(-100% - 5px)) rotate(45deg);
}

.selectedBtn_tooltip {
  width: 100%;
  text-align: left;
}

.tooltip__tip_live {
  /* border-radius: 10px; */
  /* position: relative; */
  text-align: center;
}

.tooltip__tip_live::after {
  background-color: #000000;
  display: none;
  padding: 10px 15px;
  position: absolute;
  text-align: center;
  z-index: 999;
  color: white;
}

.tooltip__tip_live::before {
  background-color: #000000;
  content: " ";
  display: none;
  position: absolute;
  height: 7px;
  z-index: 999;
}

.tooltip__tip_live:hover::after {
  display: block;
}

.tooltip__tip_live:hover::before {
  display: block;
}

.tooltip__tip_live.top::after {
  content: attr(data-tip);
  bottom: 96px;
  left: 164px;
  width: 100%;
  font-size: 7px;
  padding: 3px 5px;
  transform: translate(-50%, calc(-100% - 10px));
}

.tooltip__tip_live_dis.top::after {
  content: attr(data-tip);
  bottom: -2px;
  left: 8px;
  font-size: 7px;
  padding: 1px 5px;
  -webkit-transform: translate(-50%, calc(-100% - 10px));
  transform: translate(-50%, calc(-100% - 10px));
}

.tooltip__tip_live.top::before {
  bottom: 107px;
  left: 164px;
  transform: translate(-50%, calc(-100% - 5px)) rotate(45deg);
}

.tooltip__tip_live_dis.top::before {
  bottom: 16px;
  left: 12px;
  transform: translate(-50%, calc(-100% - 5px)) rotate(45deg);
}

.selectedBtn_tooltip {
  width: 100%;
}

.noUi-target_dup {
  /* background: #202124; */
  background: var(--widgetBg);
  transition: var(--transition);
}

.bg-black_mattie {
  /* background: #1e1e1e; */
  background: var(--widgetBg);
  transition: var(--transition);
}

.light_trend_scale {
  background: var(--widgetBg);
  transition: var(--transition);
  color: var(--textColor);
  fill: var(--textColor);
  stroke: var(--textColor);
}

.light_trend-mode {
  background: var(--widgetBg);
  transition: var(--transition);
  color: var(--textColor);
}

._ann_tooltip_logo {
  width: 100%;
  opacity: 0.3;
}

.ann_head {
  font-size: medium;
  font-family: "Ubuntu", sans-serif;
}

.left_width {
  width: 100%;
  height: 100%;
  max-height: 50em;
  overflow: scroll;
}

.right_width {
  /* width: 10%; */
}

.sub_head_para {
  color: yellow;
}

.grid {
  stroke: 10;
}

.tool_toggle_input[type="checkbox"] {
  height: 0;
  width: 0;
  visibility: hidden;
  position: absolute;
}

.tool_toggle_label {
  cursor: pointer;
  text-indent: -9999px;
  width: 23px;
  height: 12px;
  display: block;
  border-radius: 100px;
  position: relative;
}

.tool_toggle_label:after {
  content: "";
  position: absolute;
  top: 1px;
  left: 2px;
  width: 10px;
  height: 10px;
  background: var(--sidebarbg);
  border-radius: 90px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.tool_toggle_label {
  /* background: #5aba81; */
  /* background: #4b4c4c; */
  background: var(--toggleSliderBg);
}

.tool_toggle_input:checked + .tool_toggle_label:after {
  -webkit-transform: translateX(9px);
  transform: translateX(9px);
}

.tool_toggle_label:active:after {
  /* width: 130px; */
}

.tool_toggle_input:checked + ._tooltip {
  background: #5aba81;
}

.tool_toggle_input:checked + ._alert {
  background: #ff2200;
}

.tool_toggle_input:checked + ._zoom {
  background: #00bcd4;
}

/* ._tooltip{
  background: #5aba81;

} */
/* ._alert {
  background: #ff2200;
} */
/* ._zoom{
  background: #00bcd4;
} */
.muted_bg {
  background: #3c3a3a;
}

.muted_bg:after {
  background: #616161;
}

.tool_section {
  display: flex;
  justify-content: center;
  /* align-self: center; */
}

.live_section_btn {
  display: flex;
  height: 100%;
  align-items: center;
}

.drill_eff_left {
  width: 100% !important;
}

.drill_eff_item_1 {
  grid-column: 2;
  grid-row: 1;
}

.drill_eff_item_2 {
  grid-column: 2;
  grid-row: 2;
}

.drill_eff_item_3 {
  grid-column: 2;
  grid-row: 2;
}

.dv {
  fill: var(--textColor);
  font-weight: 700;
  letter-spacing: 0.1px;
}

.ddv {
  fill: var(--textColor);
  font-weight: 700;
  letter-spacing: 0.1px;
}

.dp__input {
  padding: 4px !important;
  color: #ffffff !important;
  background: #0e1016 !important;
  font-size: min(0.9vw, 12px);
}

.dp__main {
  margin: 1px 2px;
  margin-top: 0;
}

mark {
  background: unset;
  /* background: red; */
  color: white;
  font-size: 0.8vw;
  font-weight: 500;
  letter-spacing: 1.5px;
}

[type="checkbox"],
[type="radio"] {
  content: "";
  background-color: #28282f !important;
  background-color: unset !important;
  margin-left: auto;
  border-radius: 0;
}

[type="checkbox"]:checked,
[type="radio"]:checked {
  border: 1px solid #5aba81 !important;
  /* background-image: url(/img/check_ico.406e337a.svg); */
}

.dp__input {
  background-color: #262626 !important;
  color: #ffffff !important;
}

.dis_live_btn {
  position: relative;
  right: -7px;
  top: -8px;
}

.non_dis_sec {
  width: 130px;
  display: flex;
  justify-content: space-around;
  gap: 6px;
}

.actc_tool {
  font-size: 10px;
  background: #000000;
  width: auto;
  position: absolute;
  padding: 2px 5px;
  /* border-radius: 20px; */
}

.disabled {
  /* pointer-events: none; */
  pointer-events: none;
  cursor: default;
  display: inline-block;
  padding: 4px;
  background-color: #b6adb4;
  border-radius: 2px;
  border: 0px;
  text-align: center;
  /* color: #fff !important; */
  text-decoration: none !important;
  opacity: 0.5;
  z-index: -1;
}

.track_config_border {
  /* border-left: 1px solid #3f3f46;
  border-right: 1px solid #3f3f46; */
  /* margin: 2px; */
  /* margin-bottom: 1px; */
  /* border:1px solid red */
}

.track_config_border_tooltip {
  /* background: var(--colorBoxBg); */
  color: var(--textColorTooltip);
  /* color: black; */
}

.td_unit_ {
  font-size: calc((0.2em + 0.2vmin) + (0.2em + 0.2vmax));
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.depth_pick {
  background: unset !important;
  width: 100% !important;
  height: 20px !important;
  font-size: 10px !important;
  /* margin: 0 3px; */
}

.feet_ {
  width: 25%;
}

.feet_boxed {
  width: 75%;
}

.noUi-handle {
  width: 48px !important;
}

.__bg_label_boxes {
  background: var(--colorBoxBg);
  transition: var(--transition);
}

.name_numaric_con {
  width: calc(100% - 5px);
  margin: 0 3px;
  display: block;
}

.dark_trend_mode {
  background: var(--central_bg);
  transition: var(--transition);
}

.margin_top_title {
  margin-top: 0.5rem !important;
}

.colorBox_text_gray {
  color: var(--colorBoxHeadingText);
  font-weight: 600 !important;
  font-size: calc(0.29vw + 0.3vh + 0.35vmin);
  height: 14px;
  display: flex;
  align-items: end;
  justify-content: center;
  /* background: var(--colorBoxHeadBackround); */
  /* font-size: 0.55rem; */
  margin-top: 0.07rem;
}

.colorBox_num {
  color: var(--colorBoxWhiteNum);
  /* font-weight: 600; */
}

.colorBox_textMesure {
  font-size: 9px;
  color: var(--colorBoxHeadingText);
  letter-spacing: 0.7px;
}

.liveBtnDot {
  content: "";
  width: 7px;
  height: 7px;
  border-radius: 50%;
  /* background-color: #12B822; */
  /* background:var(--liveBtnGreen) */
}

.newStylesLiveBtn {
  display: flex;
  align-items: center;
  gap: 7px;
  border-radius: 10px;
  padding: 2px 8px;
  /* selectingTimeFeetColorBg */
  background: var(--liveBtnGreen) !important;
  font-weight: 700;
  letter-spacing: 1px;
  font-size: 9px !important;
  color: #ffffff !important;
}

.newStWDepth {
  justify-content: start !important;
}

.newStWDeptInput {
  background: var(--colorBoxPopUpInputBg) !important;
  border: none;
  color: var(--textColor) !important;
  margin-left: 0px;
  font-weight: 600;
  text-align: center;
  /* border-radius: 10px; */
}

.normalBg {
  background: var(--colorBoxBg);
  color: var(--selectingTimeFeetColor);
  box-shadow: 0px 4px 10px 4px rgba(0, 0, 0, 0.15);
  transition: var(--transition);
}

.timeBtn:hover {
  background: var(--activeTextColor);
  color: #ffffff;
  transition: var(--transition);
}

.activeBg {
  background: var(--activeTextColor);
  color: #ffffff;
}

.timeBtn {
  padding: 0px 7px;
  border-radius: 8px;
}

.arrangementClass {
  box-shadow: 0px 4px 10px 4px rgba(0, 0, 0, 0.15);
  background: var(--widgetBg);
}

.settingPopColor {
  color: var(--textColor);
  /* font-weight: 500; */
  letter-spacing: 1px;
  font-size: 9px;
}

.__toggle__boxes {
  /* height: 20px; */
  /* margin-top: 5px; */
  /* padding-left: 10px; */
  padding-right: 5px;
}

.__label_boxes_container {
  /* column-gap: 5px; */
  margin-top: -3px;
}

.__label_boxes {
  margin-left: 5px;
  margin-top: auto;
}

.__trend_bg_seperation {
  border: solid 0.5px;
  border-color: dimgray;
}

.value_of_Mnemonic_in_DIS {
  color: var(--textColor);
  font-size: 0.65rem;
}

.rages_of_mnemonics_in_DIS {
  padding-bottom: 0.1rem;
  color: var(--textColor);
  /* font-size: 0.55rem; */
}

mark {
  font-weight: 300px;
}

.ann_para ::v-deep ol,
ul {
  list-style-type: decimal;
  word-spacing: 2.5px;
  margin-left: 5%;
}

.annotation_desc ::v-deep h5 > strong {
  letter-spacing: 3px;
}

.annotation_desc ::v-deep p > strong {
  letter-spacing: 3px;
}

::v-deep li.ql-indent-1:not(.ql-direction-rtl) {
  list-style-type: disc;
  margin-left: 5%;
}

::v-deep li.ql-indent-2:not(.ql-direction-rtl) {
  list-style-type: disc;
  margin-left: 10%;
}

.noUi-target_dup {
  /* background: #202124; */
  background: var(--widgetBg);
  /* height: 622px;
  box-shadow: 0px 0px 8px 2px darkgray;
  width: 50px;
  opacity: 1;
  border-radius: 15px;
  top: -15px;
  left: 11px; */
  /* position: relative;
  width: 50px; */
  /* height: 100px; */
  /* height: 1.5em; */

  /* position: relative; */
}

#range1,
label {
  -webkit-tap-highlight-color: transparent;
}

#range1,
.range {
  /* border-radius: 0.75em; */
  overflow: hidden;
  margin-bottom: 1.5em;
}

#range1 {
  background-color: transparent;
  box-shadow: 0.3em 0.3em 0.4em var(--bs2) inset,
    -0.3em -0.3em 0.4em var(--bs1) inset;
  display: block;
  padding: 0 0.1em;
  transform-origin: left;
  position: absolute;
  transform: rotate(90deg);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

#range1:focus {
  outline: transparent;
}

/* #range1::-webkit-slider-thumb {
  background-color: #f45525;
  border: 50px;
  box-shadow:
    -0.3em -0.3em 0.5em #0937aa inset,
    0 -0.2em 0.2em 0 #0004,
    0.3em 0.9em 0.8em #0007;
  cursor: pointer;
  position: relative;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  transition: all 0.1s linear;
  z-index: 1;

} */

input#range1[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  z-index: 1;
  height: 3rem;
  width: 1.5rem;
  /* border-radius: 4px; */
  /* border-radius: 50%; */

  box-shadow: -0.3em -0.3em 1.5em var(--activeTextColor) inset,
    0 -1.2em 0.2em 0 #0004, 0.3em 0.9em 0.8em #0007;
  cursor: ew-resize;

  background: transparent;
  cursor: pointer;
  transition: all 0.1s linear;
  z-index: 1;
  /* background-color: #f45525;
  border: 50px;
  box-shadow:
    -0.3em -0.3em 0.5em #0937aa inset,
    0 -0.2em 0.2em 0 #0004,
    0.3em 0.9em 0.8em #0007;
  cursor: pointer;
  position: relative;
  border-radius: 50%;
  width: 200%;
  height: 200%;
  transition: all 0.1s linear;
  z-index: 1; */
}

input#range1[type="range"]:hover::-webkit-slider-thumb {
  width: 2rem;
}

/* FIREFOX */
input#range1[type="range"]::-moz-range-thumb { 
  appearance: none;
  z-index: 1;
  height: 3rem;
  width: 1.5rem;
  border-radius: 0px;
  border: 0;
  /* border-radius: 50%; */

  box-shadow: -0.3em -0.3em 1.5em var(--activeTextColor) inset,
    0 -1.2em 0.2em 0 #0004, 0.3em 0.9em 0.8em #0007;
  cursor: ew-resize;

  background: transparent;
  cursor: pointer;
  transition: all 0.1s linear;
  z-index: 1;
  /* background-color: #f45525;
  border: 50px;
  box-shadow:
    -0.3em -0.3em 0.5em #0937aa inset,
    0 -0.2em 0.2em 0 #0004,
    0.3em 0.9em 0.8em #0007;
  cursor: pointer;
  position: relative;
  border-radius: 50%;
  width: 200%;
  height: 200%;
  transition: all 0.1s linear;
  z-index: 1; */
}

input#range1[type="range"]:hover::-moz-range-thumb {
  width: 2rem;
}

#range1:focus::-webkit-slider-thumb {
  /* background-color: #5583f6;
  box-shadow:
    -0.3em -0.3em 0.5em #0b46da inset,
    0 -0.2em 0.2em 0 #0004,
    0.3em 0.9em 0.8em #0007; */
}

.range {
  position: relative;
  height: 5em;
}

.range__ticks {
  justify-content: space-between;
  align-items: center;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0.75em;
  width: calc(100% - 1.5em);
  height: 100%;
}

.range__tick,
.range__tick-text {
  display: inline-block;
}

.range__tick {
  color: var(--tick);
  font-size: 0.5em;
  text-align: center;
  width: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.range__tick-text {
  transform: translateX(-50%);
}

.track_styles {
  margin-left: 1em;
}

/* Dark mode */
</style>
